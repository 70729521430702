import { createWebHistory, createRouter } from 'vue-router'
import { UserStore } from '@/front/stores/user_store.js'

import PageIndex from './views/pages/index.vue';
import Dashboard from './views/pages/Dashboard.vue';
const LoginSuccess = () => import('./views/pages/LoginSuccess.vue');

// Reporting
const Reporting = () => import('./views/pages/Reporting.vue');
const InsightsPage = () => import('./views/pages/InsightsPage.vue');
const ExportsPage = () => import('./views/pages/ExportsPage.vue');
const RecurringExportsPage = () => import('./views/pages/RecurringExportsPage.vue');
const RecurringExportsForm = () => import('./views/pages/RecurringExportsForm.vue');
// Views
const IndexViews = () => import('./views/plays/index.vue');
const ShowView = () => import('./views/plays/show.vue');

// Viewers
const IndexViewers = () => import('./views/viewers/index.vue');
const ShowViewer = () => import('./views/viewers/show.vue');

// Management
const Donkey = () => import('./components/Donkey.vue');
const DonkeyUser = () => import('./components/DonkeyUser.vue');

// Videos
const NewVideo = () => import('./components/NewVideo.vue');
const NewVideoUpload = () => import('./components/NewVideoUpload.vue');
const NewVideoLink = () => import('./components/NewVideoLink.vue'); 
const NewVideoVimeo = () => import('./components/NewVideoVimeo.vue');
const NewVideoWistia = () => import('./components/NewVideoWistia.vue');
const NewVideoSynthesia = () => import('./components/NewVideoSynthesia.vue');
const NewVideoSettings = () => import('./components/NewVideoSettings.vue'); 
const NewVideoLoom = () => import('./components/NewVideoLoom.vue'); 
const NewVideoPanopto = () => import('./components/NewVideoPanopto.vue'); 
const NewVideoKaltura = () => import('./components/NewVideoKaltura.vue');
const IndexVideos = () => import('./views/videos/index.vue');
const EditVideo = () => import('./views/videos/Editor.vue');
const Chapters = () => import('./views/videos/Chapters.vue');
const ShowVideo = () => import('./views/videos/Playback.vue');
const VideoDesign = () => import('./views/videos/VideoDesign.vue');
const VideoSettings = () => import('./views/videos/VideoSettings.vue');
const ImportInteractions = () => import('./components/ImportInteractions.vue');
const LeadCaptureSettings = () => import('./components/LeadCaptureSettings.vue');
const VideoCaptions = () => import('./views/videos/VideoCaptions.vue');
const Transcript = () => import('./views/videos/Transcript.vue');
const EditTranscript = () => import('./views/videos/EditTranscript.vue');
const EndScreenForm = () => import('./views/videos/EndScreenForm.vue');
const MagicMenuForm = () => import('./views/videos/MagicMenuForm.vue');
const GenieForm = () => import('./views/videos/GenieForm.vue');
const VideoIntegrations = () => import('./views/videos/VideoIntegrations.vue');
const Sharing = () => import('./views/videos/Sharing.vue');
const PaywallSettings = () => import('./views/videos/PaywallSettings.vue');
const VideoPreview = () => import('./views/videos/VideoPreview.vue');
const MorePage = () => import('./views/videos/MorePage.vue');
const Branching = () => import('./views/videos/Branching.vue');
const VideoFacebook = () => import('./views/videos/integrations/VideoFacebook.vue');
const VideoHubspot = () => import('./views/videos/integrations/VideoHubspot.vue');
const VideoSynthesia = () => import('./views/videos/integrations/VideoSynthesia.vue');
const VideoGoogleAnalytics = () => import('./views/videos/integrations/VideoGoogleAnalytics.vue');
const VideoOpenAi = () => import('./views/videos/integrations/VideoOpenAi.vue');
const VideoWebhooks = () => import('./views/videos/integrations/VideoWebhooks.vue');
const VideoStripe = () => import('./views/videos/integrations/VideoStripe.vue');
const VideoZapier = () => import('./views/videos/integrations/VideoZapier.vue');
const VideoConstantContact = () => import('./views/videos/integrations/VideoConstantContact.vue');
const VideoShopify = () => import('./views/videos/integrations/VideoShopify.vue');

// Reporting
const VideoReporting = () => import('./views/pages/VideoReporting.vue');
const VideoQuestions = () => import('./views/pages/VideoQuestions.vue');
const VideoViews = () => import('./views/pages/VideoViews.vue');
// const VideoViews = () => import('./views/pages/VideoViews.vue');



// Interactions
const IndexInteractions = () => import('./views/interactions/index.vue');
const ShowInteraction = () => import('./views/interactions/show.vue');

// Account
const Account = () => import('./views/users/Account.vue');
const ConnectSuccess = () => import('./views/pages/ConnectSuccess.vue');


// User Integrations
const Integrations = () => import('./views/users/integrations/AccountIntegrationsHome.vue');
const UserHubSpotIntegration = () => import('./views/users/integrations/AccountHubSpot.vue');
const UserZapierIntegration = () => import('./views/users/integrations/AccountZapier.vue');
const UserGoogleAnalyticsIntegration = () => import('./views/users/integrations/AccountGoogleAnalytics.vue');
const AccountFacebook = () => import('./views/users/integrations/AccountFacebook.vue');
const AccountSynthesia = () => import('./views/users/integrations/AccountSynthesia.vue');
const AccountShopify = () => import('./views/users/integrations/AccountShopify.vue');
const AccountOpenAi = () => import('./views/users/integrations/AccountOpenAi.vue');
const AccountWebhooks = () => import('./views/users/integrations/AccountWebhooks.vue');
const AccountStripe = () => import('./views/users/integrations/AccountStripe.vue');
const AccountConstantContact = () => import('./views/users/integrations/AccountConstantContact.vue');
// Groups
const IndexGroups = () => import('./views/groups/index.vue');
const NewGroup = () => import('./views/groups/new.vue');
const ShowGroup = () => import('./views/groups/show.vue');


// Pages
const Buy = () => import('./views/pages/Buy.vue');
const Help = () => import('./views/pages/Help.vue');
const Affiliates = () => import('./views/pages/Affiliates.vue');
const Billing = () => import('./views/pages/Billing.vue');
const Cancel = () => import('./views/pages/Cancel.vue');
const CancelFlow = () => import('./views/pages/CancelFlow.vue');
const PauseOffer = () => import('./views/pages/PauseOffer.vue');
const Scratch = () => import('./views/pages/Scratch.vue');
const Assets = () => import('./views/pages/Assets.vue');
const UserDesign = () => import('./views/pages/UserDesign.vue');
const Organization = () => import('./views/pages/Organization.vue');
const AccessLists = () => import('./views/pages/AccessLists.vue');
const AccessListsEditor = () => import('./components/AccessListsEditor.vue');
const BulkEdit = () => import('./components/BulkEdit.vue');
const OrgUsers = () => import('./components/OrgUsers.vue');
const OrgQuotas = () => import('./components/OrgQuotas.vue');
const Sandbox = () => import('./views/pages/Sandbox.vue');

// Series
const IndexSeries = () => import('./views/series/index.vue');
const ShowSeries = () => import('./views/series/show.vue');

const router = createRouter({
  history: createWebHistory(`/`),
  routes: [
    // Authentication
    { path: '/', component: Dashboard, name: 'root_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/login/success', component: LoginSuccess, name: 'login_success_path', meta: { menu: 'SideMenu', auth: false } },
    { path: '/donkey', component: Donkey, name: 'donkey_path', meta: { menu: 'SideMenu', auth: false } },
    { path: '/donkey/user/:id', component: DonkeyUser, name: 'donkey_user_path', meta: { menu: 'SideMenu', auth: false } },

    // Reporting home
    { path: '/reporting', component: Reporting, name: 'reporting_path', meta: { menu: 'SideMenu', auth: true } },

    // Videos
    { path: '/reporting/videos/:id', component: VideoReporting, name: 'video_reporting_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/reporting/videos/:id/questions', component: VideoQuestions, name: 'video_questions_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/reporting/videos/:id/views', component: VideoViews, name: 'video_views_path', meta: { menu: 'SideMenu', auth: true } },
    // { path: '/reporting/videos/:id/interactions', component: VideoViews, name: 'video_views_path', meta: { menu: 'SideMenu', auth: true } },
    // { path: '/reporting/videos/:id/questions', component: VideoViews, name: 'video_views_path', meta: { menu: 'SideMenu', auth: true } },
    // { path: '/reporting/videos/:id/viewers', component: VideoViews, name: 'video_views_path', meta: { menu: 'SideMenu', auth: true } },

    // Views
    { path: '/reporting/views', component: IndexViews, name: 'index_views_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/reporting/views/:id', component: ShowView, name: 'show_view_path', meta: { menu: 'SideMenu', auth: true } },

    // Interactions
    { path: '/reporting/interactions', component: IndexInteractions, name: 'index_interactions_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/reporting/interactions/:id', component: ShowInteraction, name: 'show_interaction_path', meta: { menu: 'SideMenu', auth: true } },

    // Viewers
    { path: '/reporting/viewers', component: IndexViewers, name: 'index_viewers_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/reporting/viewers/:id', component: ShowViewer, name: 'show_viewer_path', meta: { menu: 'SideMenu', auth: true } },
    // Insights
    { path: '/reporting/insights', component: InsightsPage, name: 'insights_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/reporting/exports', component: ExportsPage, name: 'exports_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/reporting/exports/recurring', component: ExportsPage, name: 'recurring_exports_path', meta: { menu: 'SideMenu', auth: true, tab: '1' } },
    { path: '/reporting/exports/recurring/:id', component: RecurringExportsPage, name: 'recurring_exports_show_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/reporting/exports/recurring/new', component: RecurringExportsForm, name: 'recurring_exports_new_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/videos', component: IndexVideos, name: 'index_videos_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/videos/new', component: NewVideo, name: 'new_video_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/videos/new/settings', component: NewVideoSettings, name: 'new_video_settings_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/videos/new/upload', component: NewVideoUpload, name: 'new_video_upload_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/videos/new/link', component: NewVideoLink, name: 'new_video_link_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/videos/new/vimeo', component: NewVideoVimeo, name: 'new_video_vimeo_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/videos/new/wistia', component: NewVideoWistia, name: 'new_video_wistia_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/videos/new/synthesia', component: NewVideoSynthesia, name: 'new_video_synthesia_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/videos/new/kaltura', component: NewVideoKaltura, name: 'new_video_kaltura_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/videos/new/loom', component: NewVideoLoom, name: 'new_video_loom_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/videos/new/panopto', component: NewVideoPanopto, name: 'new_video_panopto_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/videos/:id', component: EditVideo, name: 'edit_video_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/design', component: VideoDesign, name: 'video_design_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/settings', component: VideoSettings, name: 'video_settings_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/lead-capture', component: LeadCaptureSettings, name: 'lead_capture_settings_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/chapters', component: Chapters, name: 'chapters_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/captions', component: VideoCaptions, name: 'video_captions_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/transcript', component: Transcript, name: 'transcript_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/transcript/edit', component: EditTranscript, name: 'edit_transcript_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/end-screen', component: EndScreenForm, name: 'end_screen_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/magic-menu', component: MagicMenuForm, name: 'magic_menu_form_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/import', component: ImportInteractions, name: 'import_interactions_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/genie', component: GenieForm, name: 'genie_form_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/integrations', component: VideoIntegrations, name: 'video_integrations_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/preview', component: VideoPreview, name: 'video_preview_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/share', component: Sharing, name: 'sharing_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/paywall', component: PaywallSettings, name: 'paywall_settings_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/more', component: MorePage, name: 'more_page_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/branching', component: Branching, name: 'branching_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/w/:id', component: ShowVideo, name: 'playback_path', meta: { auth: false} },
    { path: '/e/:id', component: ShowVideo, name: 'embed_path', meta: { auth: false} },
    
    // AccountIntegrations
    { path: '/videos/:id/integrations/facebook', component: VideoFacebook, name: 'video_integrations_facebook_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/integrations/hubspot', component: VideoHubspot, name: 'video_hubspot_integration_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/integrations/synthesia', component: VideoSynthesia, name: 'video_synthesia_integration_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/integrations/open-ai', component: VideoOpenAi, name: 'video_open_ai_integration_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/integrations/webhooks', component: VideoWebhooks, name: 'video_webhooks_integration_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/integrations/stripe', component: VideoStripe, name: 'video_stripe_integration_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/integrations/zapier', component: VideoZapier, name: 'video_zapier_integration_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/integrations/google-analytics', component: VideoGoogleAnalytics, name: 'video_google_analytics_integration_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/integrations/constant-contact', component: VideoConstantContact, name: 'video_constantcontact_integration_path', meta: { menu: 'VideoMenu', auth: true } },
    { path: '/videos/:id/integrations/shopify', component: VideoShopify, name: 'video_shopify_integration_path', meta: { menu: 'VideoMenu', auth: true } },


    // Account
    { path: '/pages', component: PageIndex, name: 'pages_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/connect-success', component: ConnectSuccess, name: 'connect_success_path',},
    { path: '/account', component: Account, name: 'account_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/subscribe/:plan', component: Buy, name: 'buy_path', meta: { auth: true} },
    { path: '/subscribe/:plan/annual', component: Buy, name: 'annual_buy_path', meta: { auth: true} },

    // AccountIntegrations
    { path: '/account/integrations', component: Integrations, name: 'integrations_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/integrations/hubspot', component: UserHubSpotIntegration, name: 'user_hubspot_integration_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/integrations/zapier', component: UserZapierIntegration, name: 'user_zapier_integration_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/integrations/google-analytics', component: UserGoogleAnalyticsIntegration, name: 'user_google_analytics_integration_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/integrations/facebook', component: AccountFacebook, name: 'user_facebook_integration_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/integrations/synthesia', component: AccountSynthesia, name: 'user_synthesia_integration_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/integrations/shopify', component: AccountShopify, name: 'user_shopify_integration_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/integrations/open-ai', component: AccountOpenAi, name: 'user_open_ai_integration_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/integrations/webhooks', component: AccountWebhooks, name: 'user_webhooks_integration_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/integrations/stripe', component: AccountStripe, name: 'user_stripe_integration_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/integrations/constant-contact', component: AccountConstantContact, name: 'user_constantcontact_integration_path', meta: { menu: 'SideMenu', auth: true } },

    // Groups
    { path: '/groups', component: IndexGroups, name: 'index_groups_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/groups/new', component: NewGroup, name: 'new_group_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/groups/:id', component: ShowGroup, name: 'show_group_path', meta: { menu: 'SideMenu', auth: true } },

    // Series
    { path: '/series', component: IndexSeries, name: 'index_series_path', meta: { menu: 'SideMenu', auth: true } },
    // TODO handle viewing of series
    { path: '/series/:id', component: ShowSeries, name: 'show_series_path', meta: { menu: 'SideMenu', auth: false } },

    // Pages
    { path: '/help', component: Help, name: 'help_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/affiliates', component: Affiliates, name: 'affiliates_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/billing', component: Billing, name: 'billing_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/pause', component: PauseOffer, name: 'pause_offer_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/cancel', component: Cancel, name: 'cancel_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/cancel/confirm', component: CancelFlow, name: 'cancel_flow_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/assets', component: Assets, name: 'assets_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/design', component: UserDesign, name: 'user_design_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/organization', component: Organization, name: 'organization_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/organization/users', component: OrgUsers, name: 'organization_users_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/organization/quotas', component: OrgQuotas, name: 'organization_quotas_path', meta: { menu: 'SideMenu', auth: true } }, 
    { path: '/account/organization/bulk-edit', component: BulkEdit, name: 'organization_bulk_edit_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/organization/access-lists', component: AccessLists, name: 'organization_access_lists_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/account/organization/access-lists/:id', component: AccessListsEditor, name: 'access_lists_editor_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/scratch', component: Scratch, name: 'scratch_path', meta: { menu: 'SideMenu', auth: true } },
    { path: '/sandbox', component: Sandbox, name: 'sandbox_path', meta: { menu: 'SideMenu', auth: false } },
  ]
});

// Handles 404 Not found
router.beforeEach((to, from, next) => {

  const isIframe = window !== window.parent
  const allowedPaths = ['/w/', '/e/', '/series/']
  
  if (isIframe && !allowedPaths.some(path => to.path.startsWith(path))) {
    console.log("🌜 isIframe and not allowed", to.path)
    // TODO: Do something here?
    return
  }
  
  const userStore = UserStore();
  if (!to.matched.length) {
    console.log("🔴 No route: " + to.path)
  } else if (to.meta.auth && !userStore.present) {
    window.location.href = '/login'
    // next()
  } else {
    next();
  }
});

export default router;