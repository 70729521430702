// services/interactionService.js
import { VideoService } from './VideoService' // Assuming we'll move video logic here later

export const InteractionService = {
  processShowInteraction(ann, { router, playerStore, playbackStore, videoStore, clickStore, audioStore, clipStore, annStore }) {
    // console.log("💟 InteractionService processShowInteraction", ann.type_of, ann.content + " (" + ann.exact_time + " - " + ann.exact_finish + ")")
    
    if (ann.hidden || !ann.visible) {
      return { shouldShow: false }
    }

    if (annStore.isDoneAt(ann.exact_time, ann.id)) {
      return { shouldShow: false }
    }
    // console.log("💟 InteractionService processShowInteraction", ann.type_of, ann.content + " (" + ann.exact_time + " - " + ann.exact_finish + ")")
    // Handle singular interactions
    if (this.isSingularInteraction(ann) || ann.pause) {
      // console.log("💟 InteractionService processShowInteraction singular interaction", ann.type_of)
      if (ann.pause) {
        // playerStore.pause("InteractionService processShowInteraction pause")
        if (ann.timer == -1) {
          return { shouldShow: true, action: 'pauseUntilClick', singular: true, ann }
        } else {
          return { shouldShow: true, action: 'startTimedPause', timer: ann.timer, singular: true }
        }
      }
      
      if (ann.type_of === 'video' && ann.content) {
        playerStore.pause("InteractionService processShowInteraction startVideo")
        return { 
          shouldShow: false, 
          action: 'startVideo',
          singular: true,
          processPromise: clipStore.streamById(ann.content).then(() => {
            clickStore.startInteractionClip(ann)
          })
        }
      }
      
      if (ann.type_of === 'audio' && ann.content) {
        playerStore.pause("InteractionService processShowInteraction startAudio")
        return { 
          shouldShow: false,
          action: 'startAudio',
          singular: true,
          processPromise: audioStore.streamById(ann.content).then(() => {
            clickStore.startAudio()
          })
        }
      }

      if (ann.type_of === 'resourceTray') {
        return { shouldShow: true, action: 'showMagicMenu', singular: true, ann }
      }

      if (ann.type_of === 'jump') {
        return { shouldShow: false, action: 'jump', value: ann.click_value, singular: true }
      }

      if (ann.type_of === 'variable') {
        let val = ann.value
        if (!val) {
          try { 
            let data = JSON.parse(ann.data)
            val = data.value
          } catch (e) {
            console.log("Error parsing variable data", e)
          }
        }
        return { shouldShow: false, action: 'setVariable', variable: ann.variable, value: val, singular: true }
      }

      if (ann.type_of === 'switch' && !videoStore.video.manage) {
        return { 
          shouldShow: false, 
          action: 'videoBranch',
          token: ann.click_value.split("---")[1],
          singular: true
        }
      }

      // Add other singular interaction types...
    }

    // Regular interactions (buttons, hotspots, etc)
    // console.log("💟 InteractionService processShowInteraction regular interaction", ann.type_of)
    return { shouldShow: true, singular: false }
  },

  isSingularInteraction(ann) {
    return ['video', 'audio', 'jump', 'question', 'pause', 'variable', 'redirect', 
            'switch', 'back', 'resourceTray', 'reset_viewer', 'chatGPT', 'drawing'].includes(ann.type_of)
  }
}