export const MindscriptService = {
  evaluateExpression(expression, playbackStore) {
    // Remove {{ and }} from expression and trim whitespace from ends only
    const cleanExpr = expression.replace(/{{|}}/g, '').trim()  // Changed regex to properly remove all braces
    
    // Split by operators while preserving operators and handling quoted strings
    const tokens = this.tokenize(cleanExpr)
    
    // First, check if any token resolves to a non-numeric string
    const hasStringToken = tokens.some((token, index) => {
      // Skip operators
      if (token === '+' || token === '-') return false
      const value = this.resolveToken(token, playbackStore)
      return !this.isNumeric(value)
    })

    // Start with first token
    let result = this.resolveToken(tokens[0], playbackStore)
    
    // If we have any strings, convert everything to strings and only allow concatenation
    if (hasStringToken) {
      result = String(result)
      for (let i = 1; i < tokens.length; i += 2) {
        const operator = tokens[i]
        const nextToken = tokens[i + 1]
        
        if (!nextToken) continue
        
        const nextValue = this.resolveToken(nextToken, playbackStore)
        
        if (operator === '+') {
          result = result + String(nextValue)
        }
        // Ignore subtraction in string mode
      }
    } else {
      // All tokens are numeric, do math operations
      result = Number(result)
      for (let i = 1; i < tokens.length; i += 2) {
        const operator = tokens[i]
        const nextToken = tokens[i + 1]
        
        if (!nextToken) continue
        
        const nextValue = Number(this.resolveToken(nextToken, playbackStore))
        
        if (operator === '+') {
          result += nextValue
        } else if (operator === '-') {
          result -= nextValue
        }
      }
    }
    
    return result
  },
  
  tokenize(expr) {
    const tokens = []
    let current = ''
    let inQuotes = false
    
    for (let i = 0; i < expr.length; i++) {
      const char = expr[i]
      
      if (char === '"') {
        inQuotes = !inQuotes
        current += char
      } else if ((char === '+' || char === '-') && !inQuotes) {
        if (current.trim()) {
          tokens.push(current.trim())
        }
        tokens.push(char)
        current = ''
      } else {
        current += char
      }
    }
    
    if (current.trim()) {
      tokens.push(current.trim())
    }
    
    return tokens
  },
  
  resolveToken(token, playbackStore) {
    token = token.trim()
    
    // Check if token is a quoted string
    if (token.startsWith('"') && token.endsWith('"')) {
      return token.slice(1, -1)  // Remove quotes but preserve internal spaces
    } else if (token.startsWith("'") && token.endsWith("'")) {
      return token.slice(1, -1)  // Remove quotes but preserve internal spaces
    }
    
    // If it's a variable name, get its value
    const value = playbackStore.getVariable(token)
    if (value !== undefined) {
      return value
    }
    
    // If variable doesn't exist, return the token name itself
    return token
  },

  isNumeric(value) {
    // Handle empty values
    if (!value && value !== 0) return false
    
    // Handle already-numeric values
    if (typeof value === 'number') return true
    
    // Handle string values that can be converted to numbers
    if (typeof value === 'string') {
      // Remove whitespace
      const trimmed = value.trim()
      // Check if it can be converted to a number and is not empty
      return trimmed !== '' && !isNaN(trimmed)
    }
    
    return false
  }
}