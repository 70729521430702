import { ref } from "vue";

export function TimeFormatter() {

  function getDomain(url) {
    return url.replace('https://', '').replace('http://', '').replace('www.', '').split('/')[0]
  }

  function convertDecitimeToKey(decitime) {
    if (decitime == undefined) return null
    let [seconds, tenths] = decitime.toString().split('.');

    // Pad the seconds portion with leading zeros to 6 digits
    seconds = seconds.padStart(6, '0');

    // Ensure the tenths portion is exactly 1 character long
    tenths = tenths ? tenths.padEnd(1, '0') : '0';

    // Concatenate the two parts to form the final key
    return seconds + tenths;
  }

  function formatNumber(number) {
    if (number == undefined) {
      return 0
    }
    if (number >= 9999) {
      return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    } else if (number >= 1000) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return number.toString();
    }
  }

  function getBaseDomain(url) {
    if (!url || !url.includes('http')) {
      return url
    }
    try {
        const { hostname } = new URL(url);
        return hostname;
    } catch (error) {
        console.error("Invalid URL:", error);
        return null;
    }
}



  function formatSeconds(seconds, decimals=true) {
    if (seconds < 0 || seconds == undefined) {
      return ''
    }
    var date = new Date(seconds * 1000);
    var hh = date.getUTCHours();
    var mm = date.getUTCMinutes();
    var ss = date.getSeconds();
    var ms = date.getMilliseconds();

    if (hh > 0) {
      hh = hh + ":";
    } else {
      hh = "";
    }
    if (mm < 10 && mm != 0) {
      if (hh == "") {
        mm = mm + ":";
      } else {
        mm = "0" + mm + ":"
      }

    } else if (mm > 10) {
      mm = mm + ":";
    } else if (mm == 0 || mm == 10) {
      mm = mm + ":";
    }
    if (ss < 10) { ss = "0" + ss; }
    // This formats your string to HH:MM:SS
    if (decimals) {
      if (ms == 0) {
        ms = ''
      } else {
      ms = '.'+ms.toString().substring(0,1)
      }
    } else {
      ms = ''
    }
    // This formats your string to HH:MM:SS
    return hh + mm + ss + ms
  }

  function stripHtmlAndJs(str) {
    if (!str) return '';
    
    // Remove HTML tags
    let stripped = str.replace(/<[^>]*>/g, '');
    
    // Remove JavaScript
    stripped = stripped.replace(/javascript:/gi, '');
    stripped = stripped.replace(/on\w+\s*=\s*["']?[^"']*["']?/gi, '');
    
    // Decode HTML entities
    const textarea = document.createElement('textarea');
    textarea.innerHTML = stripped;
    return textarea.value;
  }


  return { formatSeconds, getDomain, getBaseDomain, formatNumber, stripHtmlAndJs, convertDecitimeToKey };
}
