<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import { useRoute } from 'vue-router'
const router = useRouter()
const location = useRoute()
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import { InteractionStore } from '@/front/stores/interaction_store.js'
const interactionStore = InteractionStore();
import ApexCharts from 'apexcharts'
import { DateComposer } from '@/front/composables/DateComposer.js'
const { mmdd } = DateComposer()

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  height: {
    type: Number,
    default: 250
  }
})


onMounted(() => {
  updateChart()
});
const getChartData = () => {
  let defaultColor = 'blue'
  switch (props.type) {
    case 'views':
      return {
        title: `${t('common.views')}: ${interactionStore.insights.summary.views}`,
        downloadTitle: `${t('common.views')}`,
        name: t('common.views'),
        headerCategory: t('common.date'),
        headerValue: t('common.views'),
        color: '#1098ff', // Light blue
        data: interactionStore.insights.stats.map(stat => stat.views),
        formatter: (value) => {
          return value
        }
      }
    case 'interactions':
      return {
        title: `${t('common.interactions')}: ${interactionStore.insights.summary.interactions}`,
        downloadTitle: `${t('common.interactions')}`,
        name: t('common.interactions'),
        headerCategory: t('common.date'),
        headerValue: t('common.interactions'),
        data: interactionStore.insights.stats.map(stat => stat.interactions),
        color: '#5CED73', // Light green
        formatter: (value) => {
          return value
        }
      }
    case 'viewers':
      return {
        title: `${t('common.viewers')}: ${interactionStore.insights.summary.viewers}`,
        downloadTitle: `${t('common.viewers')}`,
        name: t('common.viewers'),
        headerCategory: t('common.date'),
        headerValue: t('common.viewers'),
        data: interactionStore.insights.stats.map(stat => stat.viewers),
        color: '#FFD500', // Light red
        formatter: (value) => {
          return value
        }
      }
    case 'streaming':
      return {
        title: `${t('common.streaming')}: ${interactionStore.insights.summary.view_time} ${t('common.minutes')}`,
        downloadTitle: `${t('common.streaming')}`,
        name: t('common.minutes'),
        headerCategory: t('common.date'),
        headerValue: t('common.minutes'),
        data: interactionStore.insights.stats.map(stat => stat.view_time),
        color: '#FFB3B2', // Light red
        formatter: (value) => {
          return value
        }
      }
    case 'completion':
      return {
        title: `${t('common.completion')}: ${interactionStore.insights.summary.percentage}%`,
        downloadTitle: `${t('common.completion')}`,
        name: t('common.average_completion'),
        headerCategory: t('common.date'),
        headerValue: t('common.average_completion'),
        data: interactionStore.insights.stats.map(stat => stat.percentage),
        color: '#FFAF42', // Light blue
        formatter: (value) => {
          return value + "%"
        }
      }
  }
  return []
}

const updateChart = () => {
  const chartData = getChartData()
  var options = {
    series: [chartData],
    chart: {
      height: props.height,
      // width: 600,
      type: 'area',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        export: {
          scale: undefined,
          width: undefined,
          csv: {
            filename: chartData.downloadTitle,
            columnDelimiter: ',',
            headerCategory: chartData.headerCategory,
            headerValue: chartData.headerValue,
          },
          svg: {
            filename: chartData.downloadTitle,
            columnDelimiter: ',',
            headerCategory: chartData.headerCategory,
            headerValue: chartData.headerValue,
          },
          png: {
            filename: chartData.downloadTitle,
            columnDelimiter: ',',
            headerCategory: chartData.headerCategory,
            headerValue: chartData.headerValue,
          }
        },
        autoSelected: 'zoom' 
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 6,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    title: {
      text: chartData.title,
      align: 'left',
      style: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#333'
      }
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
      }
    },

    markers: {
      size: 4,
      hover: {
        sizeOffset: 6
      },
    },
    xaxis: {
      categories: interactionStore.insights.stats.map(insight => mmdd(insight.started_at)).reverse()
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return chartData.formatter(val)
        }
      }
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return chartData.formatter(val)
            }
          }
        },
      ]
    },
    grid: {
      borderColor: '#f1f1f1',
    }
  };

  var chart = new ApexCharts(document.querySelector(`#line-chart-${props.type}`), options);
  chart.render();
}




</script>

<template>
  <div :id="`line-chart-${props.type}`">

  </div>
</template>
