import { defineStore } from 'pinia'
import { AnnStore } from '@/front/stores/ann_store.js'
  
export const ClipStore = defineStore('clip', {
  state: () => {
    return {
      player: null,
      currentTime: 0,
      aspectRatio: null,
      playerState: {},
      src: null,
    }
  },

  actions: {
    setPlayerState(state) {
      this.playerState = state
      this.aspectRatio = state.width / state.height
      console.log("🔎 ClipStore setPlayerState", this.aspectRatio)
    },
    setSrc(src) {
      this.src = src
    },
    togglePlay() {
      this.playerState.playing ? this.player.pause() : this.player.play()
    },
    seekToPercentage(percentage) {
      console.log("seekToPercentage", percentage)
      this.playerStore.seek((percentage / 100) * this.playerState.duration)
    },
    clear() {
      this.src = null
      this.playerState = {}
    },
    async streamById(id) {
      this.clear()
      console.log("🌜 ClipStore streamById", id)
      return this.Api.get(`/assets/stream/${id}`).then(response => {
        console.log("🔊 ClipStore streamById response", response.data)
        this.src = response.data.url
      })
    }
  },
  getters: {
    progress: (state) => Math.round((state.currentTime / state.playerState.duration) * 100),
  }
})