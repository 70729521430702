import { defineStore } from 'pinia'
import { UserStore } from '@/front/stores/user_store.js'
import { VideoStore } from '@/front/stores/video_store.js'
import { PlayerStore } from '@/front/stores/player_store.js'
import { AnnStore } from '@/front/stores/ann_store.js'
import { useRoute } from 'vue-router'
import { BrowserHelper } from '@/front/composables/BrowserHelper.js'
import { MessageComposer } from '@/front/composables/MessageComposer.js'


import _ from 'lodash'; // To create a deep clone of the interaction object

export const PlaybackStore = defineStore('playback', {
  state: () => {
    return {
      started: false,
      error: null,
      manager: false,
      loading: false,
      id: null, // playback id
      variables: {}, // variables to be used in the playback
      seconds: [], // watched seconds
      viewer: {}, // viewer object
      params: null, // url arguments at initialization
      url: null, // url of the playback
      browser: null, // browser object
      screenWidth: null, // screen width
      screenHeight: null, // screen height
      refer_video: null, // token of video that branched to this playback
      captions: null, // captions label being used if any
      trackingInterval: null, // interval to track playback
      lastTime: null, // last second updated,
      lastUpdate: null,
      intervalTimer: null,
      latitude: null,
      longitude: null,
      captions: null,
      userClicks: [], // array of ann ids that have been clicked
      messages: [], // array of messages sent to the window
      progressPoints: [], // array of progress points
    }
  },

  actions: {
    async update(finished=false) {
      const playerStore = PlayerStore()
      if (this.error) {
        console.log("🔴 PlaybackStore update error, returning", this.error)
        clearInterval(this.trackingInterval)
        this.trackingInterval = null
        return
      }
      // Skip paused updates unless finished or haven't updated in 2 minutes
      if (!finished && ((playerStore.playerState.paused && this.id) || (this.lastUpdate && (new Date() - this.lastUpdate) < 5000))) {
        console.log("🔴 PlaybackStore update skipped - paused or last update too recent")
        return
      }
      this.loading = true
      let endpoint = `/plays/${this.id}`
      if (!this.id) {
        console.log("🔎 PlaybackStore CREATE")
        endpoint = '/plays'
      } else {
        console.log(`🔎 PlaybackStore UPDATE, id: ${this.id}`)
      }

      console.log(this.data)
      this.data.finished = finished
      if (!this.started) {
       console.log("🔎 Not started, skipping update")
       return
      }
      return this.Api.post(endpoint, { play: this.data }).then((response)=> {
        console.log("✅ Play saved", response)
        this.id = response.data.play.id
        // TODO dont duplicate this data?
        this.viewer_id = response.data.play.viewer_id
        this.viewer.id = response.data.play.viewer_id
        this.lastUpdate = new Date()
        this.storeViewer()
      }).catch((error)=> {
        this.error = error
      }).finally(() => {
        this.loading = false
      })
    },

    setVariable(key, value) {
      
      // console.log(`⭐️ SetVariable, ${key.toUpperCase()} --> ${value}`)
      if (value !== undefined) {
        this.variables[key.toUpperCase()] = value
      } else {
        this.variables[key.toUpperCase()] = this.variables[key.toUpperCase()]
      }
      console.log("🔎 PlaybackStore setVariable", key.toUpperCase(), this.variables[key.toUpperCase()])
      const annStore = AnnStore()
      annStore.handleVariableChange()

      const { emitMessage } = MessageComposer()
      emitMessage('set_variable', { key: key.toUpperCase(), value: value })
    },

    getVariable(key) {
      if (key === undefined) return null
      let result = this.variables[key.toUpperCase()]
      if (result === undefined) {
        result = this.variables[key]
      }
      // console.log("🔎 PlaybackStore getVariable result", key, result)
      return result
    },

    resetViewer() {
      // TODO ensure works, handle conditional logic reset
      console.log("💟  PlaybackStore resetViewer")
      this.viewer = {}
      this.variables = {}
    },

    initialize(route, manager) {
      // TODO handle resetting / dont remember flag
      this.viewer = localStorage.getItem('viewer') ? JSON.parse(localStorage.getItem('viewer')) : {}
      this.manager = manager
      // Process browser and URL
      this.screenWidth = window.innerWidth
      this.screenHeight = window.innerHeight
      this.browser = BrowserHelper().browserInfo.browser.name + ' ' + BrowserHelper().browserInfo.browser.version
      this.url = document.referrer || window.location.href

      const videoStore = VideoStore()
      // Set org variables first
      Object.entries(videoStore.video.org_variables).forEach(([key, value]) => {
        this.setVariable(key, value)
      })
      // Then set video variables
      Object.entries(videoStore.video.variables).forEach(([key, value]) => {
        this.setVariable(key, value)
      })
      // Then set url variables
      this.params = route.query ? `?${Object.entries(route.query).map(([key, value]) => `${key}=${value}`).join('&')}` : null
      Object.entries(route.query).forEach(([key, value]) => {
        this.setVariable(key, value)
      })
      // Object.entries(this.variables).forEach(([key, value]) => {
      //   console.log("💟  PlaybackStore variable", key, value)
      // })
      // console.log("Variables", this.variables)
      this.viewer.name = this.variables['NAME'] || localStorage.getItem('viewer')?.name
      this.viewer.email = this.variables['EMAIL'] || localStorage.getItem('viewer')?.email
      this.viewer.phone = this.variables['PHONE'] || localStorage.getItem('viewer')?.phone
      this.viewer.custom_id = this.variables['CUSTOM_ID'] || localStorage.getItem('viewer')?.custom_id
      console.log("Viewer", this.viewer)

      // Begin looping updates
    },

    beginTracking() {
      if (this.started) {
        console.log("🔎 PlaybackStore beginTracking already started")
        return
      }
      const videoStore = VideoStore()
      if (videoStore.video.manage) {
        console.log("🔎 PlaybackStore beginTracking SKIP manage")
        return
      }
      this.started = true
      console.log("🔎 PlaybackStore beginTracking");
      window.addEventListener('beforeunload', this.finishTracking)
    
      let interval = 5000; // Start with 2 seconds
      const maxInterval = 60000; // Max interval is 60 seconds
    
      const scheduleNextUpdate = () => {
        setTimeout(async () => {
          await this.update();
    
          // Calculate next interval
          interval = Math.min(interval + 1000, maxInterval);
    
          console.log(`Next update in ${interval / 1000} seconds`);
          scheduleNextUpdate(); // Schedule the next update
        }, interval);
      };
    
      // Start the first update after 2 seconds
      this.intervalTimer = setTimeout(() => {
        this.update().then(() => {
          scheduleNextUpdate(); // Begin the backoff loop
        });
      }, 2000);
    
      // this.started = true;
    },

    finishTracking() {
      console.log("🔎 PlaybackStore finishTracking")
      if (!this.started) {
        console.log("🔴 PlaybackStore return, tracking not started")
        return
      }
      // Stop update interval
      clearTimeout(this.intervalTimer)
      this.intervalTimer = null
      // Remove listener
      window.removeEventListener('beforeunload', this.finishTracking)
      // Update to mark as finished
      this.update(true).then(()=> {
        console.log("✅ 🔴 PlaybackStore ended view")
        this.id = null
        this.started = false
        this.seconds = []
        this.lastTime = null
        this.lastUpdate = null

      })
    },

    finishLeadCapture() {
      console.log("🔎 PlaybackStore finishLeadCapture")
      this.viewer.name = this.getVariable('NAME') || this.viewer.name
      this.viewer.email = this.getVariable('EMAIL') || this.viewer.email
      this.viewer.phone = this.getVariable('PHONE') || this.viewer.phone
      this.viewer.custom_id = this.getVariable('CUSTOM_ID') || this.viewer.custom_id
      this.saveViewer()
      this.update()
    },

    getInteractionData(video, ann, val) {
      const playerStore = PlayerStore()
      return {
        play_id: this.id,
        viewer_id: this.viewer.id,
        parent_id: ann.id,
        parent_type: ann.type_of,
        parent_value: ann.content || ann.question_prompt,
        token: video.token,
        organization_id: video.organization_id,
        video_id: video.id,
        video_title: video.title,
        viewer_name: this.viewer.name, 
        viewer_email: this.viewer.email,
        viewer_phone: this.viewer.phone,
        viewer_custom_id: this.viewer.custom_id,
        type_of: 'click',
        value: val,
        time: Math.floor(playerStore.decitime),
        click_action: ann.click_action,
        click_value: ann.click_value,
        url: this.url,
        browser: this.browser,
        screen_width: this.screenWidth,
        screen_height: this.screenHeight,
        params: this.params == "?" ? null : this.params,
        variables: this.variables,
        group_id: video.group_id,
        series_id: video.series_id,
        organization_id: video.organization_id,
        question_type: ann.question_type,
        internal_label: ann.internal_label,
        // TODO on backend
        // IP address, Location,
      }
    },

    async safeTrackVideoResponse(video, ann, uploadUrl) {
      if (this.id) {
        this.trackVideoResponse(video, ann, uploadUrl)
      } else {
        this.update().then(() => {
          this.trackVideoResponse(video, ann, uploadUrl)
        })
      }
    },

    async trackVideoResponse(video, ann, uploadUrl) {
      console.log("🔎 PlaybackStore trackVideoResponse", video, ann, uploadUrl)
      if (!this.started) {
        console.log("🔎 PlaybackStore trackVideoResponse not started, skipping")
        return
      } else {
        console.log("🔎 PlaybackStore trackVideoResponse videoStore.video", video, video.manage)
      }
      // Track clicks for buttons, hotspots, images, text, etc
      console.log("🔎 PlaybackStore trackVideoResponse", ann)
      let data = this.getInteractionData(video, ann, uploadUrl)
      console.log("🔎 PlaybackStore trackVideoResponse data", data)
      // TODO save click
      this.Api.post('/reporting/interactions/video_response', { interaction: data }).then((response) => {
        console.log("✅ Interaction saved", response)
      }).catch((error) => {
        console.log("🔴 PlaybackStore trackVideoResponse error", error)
      })

    },

    async safeTrackDrawingResponse(video, ann, uploadUrl) {
      if (this.id) {
        this.trackDrawingResponse(video, ann, uploadUrl)
      } else {
        this.update().then(() => {
          this.trackDrawingResponse(video, ann, uploadUrl)
        })
      }
    },

    async trackDrawingResponse(video, ann, content) {
      console.log("🔎 PlaybackStore trackDrawingResponse", video, ann, content)
      if (!this.started) {
        console.log("🔎 PlaybackStore trackDrawingResponse not started, skipping")
        return
      } else {
        console.log("🔎 PlaybackStore trackDrawingResponse videoStore.video", video, video.manage)
      }
      // Track clicks for buttons, hotspots, images, text, etc
      console.log("🔎 PlaybackStore trackDrawingResponse", ann)
      let data = this.getInteractionData(video, ann, content)
      console.log("🔎 PlaybackStore trackDrawingResponse data", data)
      // TODO save click
      this.Api.post('/reporting/interactions/drawing_response', { interaction: data }).then((response) => {
        console.log("✅ Interaction saved", response)
      }).catch((error) => {
        console.log("🔴 PlaybackStore trackDrawingResponse error", error)
      })
    },

    async safeTrackImageResponse(video, ann, uploadUrl) {
      if (this.id) {
        this.trackImageResponse(video, ann, uploadUrl)
      } else {
        this.update().then(() => {
          this.trackImageResponse(video, ann, uploadUrl)
        })
      }
    },

    async trackImageResponse(video, ann, uploadUrl) {
      console.log("🔎 PlaybackStore trackImageResponse", video, ann, uploadUrl)
      if (!this.started) {
        console.log("🔎 PlaybackStore trackImageResponse not started, skipping")
        return
      } else {
        console.log("🔎 PlaybackStore trackImageResponse videoStore.video", video, video.manage)
      }
      // Track clicks for buttons, hotspots, images, text, etc
      console.log("🔎 PlaybackStore trackImageResponse", ann)
      let data = this.getInteractionData(video, ann, uploadUrl)
      console.log("🔎 PlaybackStore trackImageResponse data", data)
      // TODO save click
      this.Api.post('/reporting/interactions/image_response', { interaction: data }).then((response) => {
        console.log("✅ Interaction saved", response)
      }).catch((error) => {
        console.log("🔴 PlaybackStore trackImageResponse error", error)
      })
    },

    async safeTrackAudioResponse(video, ann, uploadUrl) {
      if (this.id) {
        this.trackAudioResponse(video, ann, uploadUrl)
      } else {
        this.update().then(() => {
          this.trackAudioResponse(video, ann, uploadUrl)
        })
      }
    },

    async trackAudioResponse(video, ann, uploadUrl) {
      console.log("🔎 PlaybackStore trackAudioResponse", video, ann, uploadUrl)
      if (!this.started) {
        console.log("🔎 PlaybackStore trackAudioResponse not started, skipping")
        return
      } else {
        console.log("🔎 PlaybackStore trackAudioResponse videoStore.video", video, video.manage)
      }
      // Track clicks for buttons, hotspots, images, text, etc
      console.log("🔎 PlaybackStore trackAudioResponse", ann)
      let data = this.getInteractionData(video, ann, uploadUrl)
      console.log("🔎 PlaybackStore trackAudioResponse data", data)
      // TODO save click
      this.Api.post('/reporting/interactions/audio_response', { interaction: data }).then((response) => {
        console.log("✅ Interaction saved", response)
      }).catch((error) => {
        console.log("🔴 PlaybackStore trackAudioResponse error", error)
      })
    },

    async safeTrackViewerClick(video, ann) {

      if (this.id) {
        this.trackViewerClick(video, ann)
      } else {
        this.update().then(() => {
          this.trackViewerClick(video, ann)
        })
      }
    },

    trackViewerClick(video, ann) {
      const videoStore = VideoStore()
      if (videoStore.video.manage) {
        console.log("🔎 PlaybackStore trackViewerClick manage, skipping")
        return
      } else {
        console.log("🔎 PlaybackStore trackViewerClick videoStore.video", video, video.manage)
      }
      // Track clicks for buttons, hotspots, images, text, etc
      console.log("🔎 PlaybackStore trackViewerClick", ann)
      let val = ann.transformed_content || ann.content
      let data = this.getInteractionData(video, ann, val)
      console.log("🔎 PlaybackStore trackViewerClick data", data)
      this.emitInteraction(data)
      // TODO save click
      this.Api.post('/reporting/interactions', { interaction: data }).then((response) => {
        console.log("✅ Interaction saved", response)
      }).catch((error) => {
        console.log("🔴 PlaybackStore trackViewerClick error", error)
      })
    },

    async safeTrackResponse(video, ann, response, correctness = null) {
      const videoStore = VideoStore()
      if (videoStore.video.manage) {
        console.log("🔎 PlaybackStore safeTrackResponse manage, skipping")
        return
      } else {
        console.log("🔎 PlaybackStore safeTrackResponse videoStore.video", video, video.manage)
      }
      console.log("🔎 PlaybackStore safeTrackResponse", ann, response, correctness)

      if (this.id) {
        this.trackResponse(video, ann, response, correctness)
      } else {
        this.update().then(() => {
          this.trackResponse(video, ann, response, correctness)
        })
      }
    },

    async trackResponse(video, ann, response, correctness = null) {
      // TODO track the response
      console.log("🔎 PlaybackStore trackResponse", ann, response)
      let data = this.getInteractionData(video, ann, response)
      data.type_of = 'reply'
      if (correctness && correctness.correctValue) {
        console.log("🔎 PlaybackStore trackResponse correctness", correctness)
        data.correct = correctness.isCorrect
        data.correct_answer = correctness.correctValue
      }
      this.emitInteraction(data)
      console.log("🔎 PlaybackStore trackResponse data", data)
      // TODO save click
      this.Api.post('/reporting/interactions', { interaction: data }).then((response) => {
        console.log("✅ Interaction saved", response)
      }).catch((error) => {
        console.log("🔴 PlaybackStore trackResponse error", error)
      })
    },

    saveViewer() {
      if (this.viewer.id) {
        let data = { 
          id: this.viewer.id,
          name: this.viewer.name,
          email: this.viewer.email,
          phone: this.viewer.phone,
          custom_id: this.viewer.custom_id,
          variables: this.variables
        }
        this.Api.put(`/reporting/viewers/${this.viewer.id}`, { viewer: data }).then((response) => {
          console.log("✅ Viewer saved", response)
          this.storeViewer()
        }).catch((error) => {
          console.log("🔴 PlaybackStore saveViewer error", error)
        })
      } else {
        console.log("🔴 PlaybackStore saveViewer no viewer id")
      }
    },


    handleTimeUpdate(time) {
      // Start tracking if not already started
      if (time > 0 && !this.started) {
        this.beginTracking()
      }

      // Update last time
      const newTime = Math.floor(time)
      if (newTime !== this.lastTime) {
        const videoStore = VideoStore()
        console.log("🔎 PlaybackStore newTime", newTime)
        this.lastTime = newTime
        this.seconds.push(newTime)
        let percent = Math.floor(100 * (newTime / (videoStore.video.duration || 1)))
        // console.log("🔎 PlaybackStore handleTimeUpdate percent", percent)
        this.handleNewPercentage(percent)
      }
    },

    handleNewPercentage(percent) {
      const { emitMessage, trackGtagEvent } = MessageComposer()
      console.log("🔎 PlaybackStore handleNewPercentage", percent)
      if (percent >= 100) {
        if (this.progressPoints.includes(100)) {
          return
        }
        this.progressPoints.push(100)
        emitMessage('progress_100')
        trackGtagEvent('Reached 100%', 'Video Player Events');
        // TODO: End event??
      } else if (percent >= 90) {
        if (this.progressPoints.includes(90)) {
          return
        }
        this.progressPoints.push(90)
        emitMessage('progress_90')
        trackGtagEvent('Reached 90%', 'Video Player Events');
      } else if (percent >= 75) {
        if (this.progressPoints.includes(75)) {
          return
        }
        this.progressPoints.push(75)
        emitMessage('progress_75')
        trackGtagEvent('Reached 75%', 'Video Player Events');
      } else if (percent >= 50) {
        if (this.progressPoints.includes(50)) {
          return
        }
        this.progressPoints.push(50)
        emitMessage('progress_50')
        trackGtagEvent('Reached 50%', 'Video Player Events');
      } else if (percent >= 25) {
        if (this.progressPoints.includes(25)) {
          return
        }
        this.progressPoints.push(25)
        emitMessage('progress_25')
        trackGtagEvent('Reached 25%', 'Video Player Events');
      } else if (percent >= 10) {
        if (this.progressPoints.includes(10)) {
          return
        }
        this.progressPoints.push(10)
        emitMessage('progress_10')
        trackGtagEvent('Reached 10%', 'Video Player Events');
      }
    },

    emitInteraction(data) {
      const { emitMessage } = MessageComposer()
      console.log("🔎 PlaybackStore emit viewer_interaction", data)
      let object = {

        interaction_type: data.type_of,
        interaction_value: data.value,
        internal_label: data.internal_label,
        parent_id: data.parent_id,
        parent_type: data.parent_type,
        parent_value: data.parent_value,
        question_style: data.question_type, // Have to keep using question_style for existing customers
        correct_answer: data.correct_answer,
        correct: data.correct,
      }
      emitMessage('viewer_interaction', object)
    },

    storeViewer() {
      localStorage.setItem('viewer', JSON.stringify(this.viewer))
      localStorage.setItem('variables', JSON.stringify(this.variables))
    }
    
  },
  getters: {
    data: (state) => {
      const videoStore = VideoStore()
      const data = {
        seconds: state.seconds,
        variables: state.variables,
        viewer_id: state.viewer?.id,
        guestName: state.viewer?.name, // TODO rename fields
        guestEmail: state.viewer?.email, // TODO rename fields
        guestPhone: state.viewer?.phone, // TODO rename fields
        guestID: state.viewer?.custom_id, // TODO rename fields
        screenWidth: state.screenWidth,
        screenHeight: state.screenHeight,
        refer_video: state.refer_video,
        captions: state.captions,
        url: state.url,
        token: videoStore.video.token,
        params: state.params,
        browser: state.browser,
        latitude: state.latitude,
        longitude: state.longitude,
        captions: state.captions,
      }
      return data
    }
  }
})

