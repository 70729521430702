import { defineStore } from 'pinia'
import { PlayerStore } from '@/front/stores/player_store.js'
import { PlaybackStore } from '@/front/stores/playback_store.js'
import { AnnStore } from '@/front/stores/ann_store.js'

export const ClickStore = defineStore('click', {
  state: () => {
    return {
      activeMessage: null,
      activeArticle: null,
      activeImageUrl: null,
      genieActive: false,
      activeLink: null,
      activeCollect: null,
      collectPrompt: null,
      magicMenuActive: false,
      guideActive: false,
      clipActive: false,
      interactionClipActive: false,
      isDrawing: false,
      activeDrawing: null,
      activeClipAnn: null,
      audioActive: false,
      activeTimedPause: null,
      captureLeadActive: false, // true just for debugging
      questionActive: null,
      questionCorrectness: {
        visible: false,
        set: false,
        isCorrect: false,
        correctValue: '',
        answerValue: '',
        action: '',
        value: '',
        force: false,
      },
      pollActive: false,
      activePauseAmounts: {
        original: 0,
        current: 0,
        progress: 0,
      }
    }
  },

  actions: {
    resetQuestionCorrectness() {
      this.questionCorrectness = {
        visible: false,
        set: false,
        isCorrect: false,
        correctValue: '',
        answerValue: '',
        action: '',
        value: '',
        force: false,
      }
    },
    startInteractionClip(ann) {
      console.log("clickStorestartInteractionClip!", ann)
      this.interactionClipActive = true
      this.activeClipAnn = ann
    },
    finishInteractionClip() {
      this.interactionClipActive = false
      this.activeClipAnn = null
    },
    startClip() {
      console.log("clickStore startClip!")
      this.clipActive = true
    },
    stopClip() {
      console.log("clickStore stopClip!")
      this.finishInteractionClip()
      this.clipActive = false
    },
    startAudio() {
      this.audioActive = true
    },
    stopAudio() {
      this.audioActive = false
    },
    startCollect(collect) {
      console.log("clickStore startCollect", collect)
      this.activeCollect = true
      this.collectPrompt = collect
    },
    stopCollect() {
      console.log("clickStore stopCollect", this.activeCollect)
      this.activeCollect = false
    },
    showMagicMenu() {
      // TODO handle pausing option
      this.magicMenuActive = true
    },
    hideMagicMenu() {
      this.magicMenuActive = false
    },
    showGuide() {
      // TODO handle pausing option
      console.log("clickStore showGuide")
      this.guideActive = true
      console.log("clickStore guideActive", this.guideActive)
    },
    hideGuide() {
      console.log("clickStore hideGuide")
      this.guideActive = false
    },
    setActiveMessage(message) {
      this.activeMessage = message
    },
    clearActiveMessage() {
      this.activeMessage = null
    },
    setActiveArticle(article) {
      this.activeArticle = article
    },
    clearActiveArticle() {
      this.activeArticle = null
    },
    setActiveImageUrl(url) {
      this.activeImageUrl = url
    },
    clearActiveImageUrl() {
      this.activeImageUrl = null
    },
    startLink(url) {
      this.activeLink = url
    },
    stopLink() {
      this.activeLink = null
    },
    startGenie() {
      this.genieActive = true
    },
    stopGenie() {
      this.genieActive = false
    },
    startChatGPT() {
      this.chatGPTActive = true
    },
    stopChatGPT() {
      this.chatGPTActive = false
    },
    // For showing drawings during playback
    showDrawing(ann, timer=true) {
      const playerStore = PlayerStore()
      playerStore.pause("clickStore showDrawing " + ann.timer)
      this.activeDrawing = ann
      if (timer) {
        this.startTimedPause(ann.timer)
      }
    },
    hideDrawing() {
      this.activeDrawing = null
      this.clearTimedPause()
    },
    // For creating drawings
    startDrawing() {
      this.isDrawing = true
    },
    stopDrawing() {
      this.isDrawing = null
    },
    resetAll() {
      console.log("clickStore resetAll!")
      this.clearActiveMessage()
      this.clearActiveArticle()
      this.clearActiveImageUrl()
      this.stopChatGPT()
      this.stopGenie()
      this.stopCollect()
      this.stopLink()
      this.stopClip()
      this.stopAudio()
      this.clearTimedPause()
      this.finishQuestions()
      this.stopDrawing()
      this.hideDrawing()
      this.resetQuestionCorrectness()
    },

    askGenie(video, question) {
      console.log("clickStore askGenie", video, question)
      return this.Api.post(`/genie/${video.id}/ask`, { prompt: question })
    },

    askChatGPT(video, question, variable = null) {
      console.log("clickStore askChatGPT", video, question)
      return this.Api.post(`/genie/${video.id}/chat`, { prompt: question }).then((response) => {
        console.log("clickStore askChatGPT response", response)
        if (variable) {
          const playbackStore = PlaybackStore()
          console.log("clickStore askChatGPT setting variable", variable, response.data.response)
          playbackStore.setVariable(variable, response.data.response)
        }
      })
    },
    startLeadCapture() {
      console.log("clickStore startLeadCapture")
      this.captureLeadActive = true
      const playerStore = PlayerStore()
      playerStore.pause("clickStore startLeadCapture")
      if (playerStore.started) {  
        const playbackStore = PlaybackStore()
        playbackStore.beginTracking()
      }
    },
    stopLeadCapture() {
      console.log("clickStore stopLeadCapture")
      this.captureLeadActive = false
      const playbackStore = PlaybackStore()
      playbackStore.finishLeadCapture()
      const playerStore = PlayerStore()
      playerStore.play("clickStore stopLeadCapture")
    },

    askCollect(video, question) {
      // console.log("askCollect", video, question)
      console.log("TODO askCollect", video, question)
      // return this.Api.post(`/collect/${video.id}/ask`, { prompt: question })
    },

    showQuestion(ann) {
      const playerStore = PlayerStore()
      playerStore.pause("clickStore showQuestion")
      this.resetQuestionCorrectness()
      this.questionActive = ann
      this.questionActive.showing_hint = false
      if (ann.question_use_time_limit) {
        this.startTimedPause(ann.timer)
      }
    },
    finishQuestions() {
      const annStore = AnnStore()
      annStore.clearQuestionQueue()
      this.questionActive = null
      this.finishTimedPause()
    },
    resetQuestionCorrectness() {
      this.questionCorrectness = {
        visible: false,
        set: false,
        isCorrect: false,
        correctValue: '',
        answerValue: '',
        action: '',
        value: '',
        force: false,
      }
    },


    async startTimedPause(seconds) {
      console.log("clickStore startTimedPause", seconds)
      this.clearTimedPause()
      return new Promise((resolve) => {
        console.log("startTimedPause", seconds)
        this.activePauseAmounts.original = seconds * 1000
        this.activePauseAmounts.current = 0
        this.activePauseAmounts.progress = 0
        let intervalAmount = 10
        this.activeTimedPause = setInterval(() => {
          // console.log("💟  ClickStore activePause", this.activePauseAmounts.current, this.activePauseAmounts.progress)
          this.activePauseAmounts.current += intervalAmount
          this.activePauseAmounts.progress = 100 * (this.activePauseAmounts.current / this.activePauseAmounts.original)
          // console.log("💟  ClickStore activePauseAmounts.progress", this.activePauseAmounts.progress)
          if (this.activePauseAmounts.current >= this.activePauseAmounts.original) {
            console.log("💟  ClickStore activePause finished!")
            this.finishTimedPause()
          }
        }, intervalAmount)
      })
    },
    clearTimedPause() {
      if (!this.activeTimedPause) return
      clearInterval(this.activeTimedPause)
      this.activeTimedPause = null
      this.activePauseAmounts = {
        original: 0,
        current: 0,
        progress: 0,
      }
    },
    finishTimedPause() {
      if (!this.activeTimedPause) return
      const playerStore = PlayerStore()
      const wasPausing = !!this.activeTimedPause
      console.log("💟  ClickStore finishTimedPause, wasPausing", wasPausing)
      if (this.questionActive) {
        const annStore = AnnStore()
        annStore.tryFinishQuestion()
      } else if (this.activeDrawing) {
        this.hideDrawing()
      }
      this.clearTimedPause()
      if (wasPausing) {
        playerStore.play("clickStore finishTimedPause")
      }
    }

  },

  getters: {
    seekbarShouldBeHidden: (state) => {
      return state.activeTimedPause
        || state.hasActiveDialog
        || state.interactionClipActive
    },
    isForceCorrect: (state) => {
      return state.questionActive && state.questionActive.question_repeat_until_correct
    },
    hasActiveDialog: (state) => {
      if (state.activeMessage) {
        console.log("🧤 activeMessage")
        return true
      } else if (state.activeArticle) {
        console.log("🧤 activeArticle")
        return true
      } else if (state.questionActive) {
        console.log("🧤 questionActive")
        return true
      } else if (state.captureLeadActive) {
        console.log("🧤 captureLeadActive")
        return true
      } else if (state.genieActive) {
        console.log("🧤 genieActive")
        return true
      } else if (state.chatGPTActive) {
        console.log("🧤 chatGPTActive")
        return true
      } else if (state.activeCollect) {
        console.log("🧤 activeCollect")
        return true
      } else if (state.magicMenuActive) {
        console.log("🧤 magicMenuActive")
        return true
      } else if (state.clipActive) {
        console.log("🧤 clipActive")
        return true
      } else if (state.audioActive) {
        console.log("🧤 audioActive")
        return true
      } else if (state.guideActive) {
        console.log("🧤 guideActive")
        return true
      } else {
        return false
      }
    }
  }


})