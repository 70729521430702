import { ref } from "vue";
import { UploadStore } from '@/front/stores/upload_store.js';



export function LinkValidator() {

  function isValidURL(link) {
    // return link.match(/^https?:\/\/[^\s/$.?#].[^\s]*$/i)
    const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/;
    return regex.test(link);
  }

  function isValidEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  function isValidPhone(phone) {
    const regex = /^(\+?\d{1,3}[-.\s]?)?(\(?\d{2,4}\)?[-.\s]?)?\d{3,4}[-.\s]?\d{3,9}$/;
    return regex.test(phone)
  }

  function isVideoLink(link) {
    const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[\w.-]*)*\/?.*\.(mp4|m3u8)(\?.*)?$/i;
    return regex.test(link);
  }

  function isVimeoLink(link) {
    const regex = /^(https?:\/\/)?(www\.)?vimeo\.com\/(\d+)$/i;
    return regex.test(link);
  }

  function isYoutubeLink(link) {
    const regex = /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/i;
    return regex.test(link);
  }

  function isWistiaLink(link) {
    const regex = /^(https?:\/\/)?(www\.)?wistia\.com\/embed\/(\w+)$/i;
    return regex.test(link);
  }

  function isPanoptoLink(link) {
    const regex = /^(https?:\/\/)?(www\.)?panopto\.com\/Panopto\/Pages\/Viewer.aspx\?id=([a-zA-Z0-9_-]+)$/i;
    return regex.test(link);
  }

  function isKalturaLink(link) {
    return link.includes('kaltura.com')
    const regex = /^(https?:\/\/)?(www\.)?kaltura\.com\/p\/(\d+)\/sp\/(\d+)\/embedIframeJs\/uiconf_id\/(\d+)\/partner_id\/(\d+)\/embed_id\/(\w+)$/i;
    return regex.test(link);
  }

  function isLoomLink(link) {
    const regex = /^(https?:\/\/)?(www\.)?loom\.com\/share\/([a-zA-Z0-9_-]+)$/i;
    return regex.test(link);
  }

  function isHeyGenLink(link) {
    const regex = /^(https?:\/\/)?(www\.)?heygen\.ai\/watch\/([a-zA-Z0-9_-]+)$/i;
    return regex.test(link);
  }

  function isSynthesiaLink(link) {
    const regex = /^(https?:\/\/)?(www\.)?synthesia\.io\/watch\/([a-zA-Z0-9_-]+)$/i;
    return regex.test(link);
  }

  function getVimeoID(link) {
    const regex = /\/(\d+)(?:\/|\.)/;
    const match = link.match(regex);
    return match ? match[1] : null;
  }

  function getWistiaID(link) {
    const regex = /(?:medias|embed\/iframe)\/([a-zA-Z0-9]+)/;
    const match = link.match(regex);
    return match ? match[1] : null;
  }

  function getLoomID(link) {
    const regex = /share\/([a-z0-9]{32})/;
    const match = link.match(regex);
    return match ? match[1] : null;
  }

  function getPanoptoID(link) {
    const regex = /[?&]id=([a-z0-9-]+)/i;
    const match = link.match(regex);
    return match ? match[1] : null;
  }



  async function isReachable(link) {
    const store = UploadStore();
    return store.checkLink(link)
  }

  return {
    isValidURL,
    isValidEmail,
    isValidPhone,
    isVideoLink,
    isVimeoLink,
    isYoutubeLink,
    isWistiaLink,
    isPanoptoLink,
    isKalturaLink,
    isLoomLink,
    isHeyGenLink,
    isSynthesiaLink,
    isReachable,
    getVimeoID,
    getWistiaID,
    getLoomID,
    getPanoptoID,
  };
}