import { defineStore } from 'pinia'

export const InteractionStore = defineStore('interactions', {
  state: () => {
    return {
      interactions: [],
      interaction: {},
      columns: ['DATE', 'NAME', 'VIDEO', 'PROMPT', 'VALUE', 'CORRECT'],
      loading: false,
      pagination: {page: 1},
      insights: null,
      filters: {}
    }
  },

  actions: {
    async getInsights() {
      this.loading = true
      return this.Api.get('/reporting/insights').then(response => {  
        console.log("🌞 Insights: ", response)
        this.insights = response.data;
      }).finally(() => {
        this.loading = false
      })
    },

    async getResponses(id) {
      return this.Api.get(`/reporting/interactions/${id}/responses`).then(response => {  
        console.log("🌞 Responses: ", response)
        return response.data.interactions;
      })
    },

    async index(path) {
      this.loading = true
      return this.Api.get(path).then(response => {  
        
        this.pagination = response.data.pagination;
        this.interactions = response.data.interactions;        
      }).finally(() => {
        this.loading = false
      })
    },

    async show(id) {
      this.loading = true
      return this.Api.get(`/interactions/${id}`).then(response => {  
        this.interaction = response.data.interaction;        
      }).finally(() => {
        this.loading = false
      })
    },

    async destroy(id) {      
      return this.Api.destroy(`/interactions/${id}`).then(response => {  
        this.errors = {};
      }).catch(error => {
        this.errors = error.response.data.errors;
      }) 
    },
    async submitExport() {
      return this.Api.post(`/reporting/interactions/export`, {filters: this.filters}).then(response => {  
        return response.data.export;      
      }).finally(() => {
        this.loading = false
      })
    },
  }
})