<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import { useRoute } from 'vue-router'
const router = useRouter()
const location = useRoute()
import { useToast } from 'primevue/usetoast';
const toast = useToast();
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import { InteractionStore } from '@/front/stores/interaction_store.js'
const interactionStore = InteractionStore();
import LineChart from '@/front/components/LineChart.vue'
import BarChart from '@/front/components/BarChart.vue'

const loaded = ref(false)
onMounted(() => {
  getData()
});

const getData = async () => {
  interactionStore.getInsights().then(response => {
    loaded.value = true
  })
}


</script>

<template>
  <div v-if="loaded">
    <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 px-8 mt-4">
      <div class="p-4 text-center bg-white rounded-2xl shadow-xl">
        <LineChart v-if="loaded" type="views" />
      </div>
      <div class="p-4 text-center bg-white rounded-2xl shadow-xl">
        <LineChart v-if="loaded" type="interactions" />
      </div>
      <div class="p-4 text-center bg-white rounded-2xl shadow-xl">
        <LineChart v-if="loaded" type="viewers" />
      </div>
      <div class="p-4 text-center bg-white rounded-2xl shadow-xl">
        <LineChart v-if="loaded" type="streaming" />
      </div>
      <div class="p-4 text-center bg-white rounded-2xl shadow-xl">
        <LineChart v-if="loaded" type="completion" />
      </div>
      <div class="p-4 text-center bg-white rounded-2xl shadow-xl">
        <BarChart v-if="loaded" type="leads" />
      </div>
      <div class="p-4 text-center bg-white rounded-2xl shadow-xl">
        <BarChart v-if="loaded" type="countries" />
      </div>
      <div class="p-4 text-center bg-white rounded-2xl shadow-xl">
        <BarChart v-if="loaded" type="questions" />
      </div>
      <div class="p-4 text-center bg-white rounded-2xl shadow-xl">
        <BarChart v-if="loaded" type="devices" />
      </div>
      <div class="col-span-full p-4 text-center bg-white rounded-2xl shadow-xl">
        <BarChart v-if="loaded" type="videos" :height="500"/>
      </div>
    </div>
  </div>
</template>
