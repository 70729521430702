import { TimeFormatter } from '@/front/composables/TimeFormatter.js'
export function StringComposer(annStore=null) {
  function transformContent(str, variables) {
    // console.log("💟  StringComposer transformContent", str, variables)
    if (typeof str !== 'string') {
      throw new TypeError('First argument must be a string.');
    }

    if (typeof variables !== 'object' || variables === null) {
      throw new TypeError('Second argument must be a non-null object.');
    }

    // Create a copy of replacements with lowercased keys for case-insensitive matching
    const variablesLower = {};
    for (const key in variables) {
      if (Object.prototype.hasOwnProperty.call(variables, key)) {
        variablesLower[key.toLowerCase()] = variables[key];
      }
    }

    // Regular expression to match {{ word }}, {{Word}}, {{ WORD }}, etc.
    const regex = /{{\s*([a-zA-Z0-9_]+)\s*}}/gi;

    // Replace each placeholder with the corresponding replacement
    const replacedStr = str.replace(regex, (match, p1) => {
      const key = p1.toLowerCase();
      if (key in variablesLower) {
        return variablesLower[key];
      } else {
        // If no replacement found, return the original match or decide to replace with empty string
        // return match; // or return ''; to remove the placeholder
        return ''
      }
    });

    return replacedStr;
  }

  function truncate(input, length = 10) {
    if (input && input.length > length) {
      return input.substring(0, length) + '...';
    }
    return input;
  };

  function fixUrl(url) {
    if (!url) return ''
    if (url.startsWith('mailto:') || url.startsWith('tel:')) {
      return url
    }
    url = url.trim()
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url
    }
    return url
  }

  function uppercase(str) {
    if (!str) return '';
    return str.toUpperCase();
  }

  function lowercase(str) {
    if (!str) return '';
    return str.toLowerCase();
  } 

  function capitalizeWords(str) {
    if (!str) return '';
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }

  function getS3Key(url) {
    if (!url) return '';

    // Split after .com if it exists
    let parts = url.split('.com');
    let result = parts.length > 1 ? parts[1] : url;

    // Split at ? if it exists
    parts = result.split('?');
    result = parts[0];

    // Remove leading slash if present
    return result.startsWith('/') ? result.slice(1) : result;
    return
  }

  function objectToQueryString(obj) {
    const filteredObj = Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => value !== '')
    );
    const queryString = new URLSearchParams(filteredObj).toString();
    return queryString ? `?${queryString}` : '';
  }

  function getFileNameWithoutExtension(url) {
    if (!url) return '';

    // Extract the file name from the URL
    const fileName = url.split('/').pop();

    // Remove the timestamp and dash at the beginning (if present)
    const nameWithoutTimestamp = fileName.replace(/^\d+-/, '');

    // Remove the file extension
    return nameWithoutTimestamp.replace(/\.[^/.]+$/, '');
  }
  function getFileNameWithExtension(url) {
    if (!url) return '';

    // Extract the file name from the URL
    const fileName = url.split('/').pop();

    // Remove the timestamp and dash at the beginning (if present)
    const nameWithoutTimestamp = fileName.replace(/^\d+-/, '');

    // Keep the file extension
    return nameWithoutTimestamp;
  }

  function toUpper(str) {
    return (str || '').toUpperCase()
  }

  function toLower(str) {
    return (str || '').toLowerCase()
  }

  function getFileName(filePath) {
    if (!filePath) return ''
    return filePath.substring(filePath.lastIndexOf('/') + 1);
  }

  function getFileType(filePath) {
    if (!filePath) return ''
    return toUpper(filePath.split('.').pop());
  }

  function annTypeToWord(type) {
    const { t } = useI18n({})

    switch (type) {
      case 'redirect':
        return t('common.redirect_link')
      case 'jump':
        return t('common.change_time')
      case 'switch':
        return t('common.switch_video')
      case 'back':
        return t('common.previous_video')
      case 'pause':
        return t('common.pause')
      case 'resourceTray':
        return t('common.open_magic_menu')
      case 'variable':
        return t('common.set_variable')
      case 'reset_viewer':
        return t('common.reset_viewer')
      case 'chatGPT':
        return t('common.ask_chatgpt')
      default:
        return t('common.none')
    }
  }

  function needsClickValue(action) {
    return !['none', 'back', 'track', 'resourceTray'].includes(action)
  }

  function clickActions() {
    const { t } = useI18n({})
    return [
      { label: t('common.continue'), value: 'track' },
      { label: t('common.open_link'), value: 'link' },
      { label: t('common.change_time'), value: 'jump' },
      { label: t('common.switch_video'), value: 'video' },
      { label: t('common.show_message'), value: 'message' },
      { label: t('common.switch_to_previous_video'), value: 'back' },
      { label: t('common.show_article'), value: 'article' },
      { label: t('common.show_image'), value: 'view' },
      { label: t('common.open_genie_ai'), value: 'genie' },
      { label: t('common.ask_chatgpt'), value: 'openai' },
      // Done above this
      { label: t('common.play_video_clip'), value: 'clip' },
      { label: t('common.play_audio_clip'), value: 'audio' },
      { label: t('common.start_email'), value: 'email' },
      { label: t('common.start_call'), value: 'phone' },
      { label: t('common.open_link_modal'), value: 'modal' },
      { label: t('common.collect_response'), value: 'collect' },
      { label: t('common.download_file'), value: 'download' },
      { label: t('common.open_magic_menu'), value: 'resourceTray' },
      { label: t('common.show_card'), value: 'show_card' },
      { label: t('common.connector_get_request'), value: 'get' },
      { label: t('common.connector_post_request'), value: 'post' },
      { label: t('common.add_to_cart'), value: 'cart' },

      { label: t('common.none'), value: 'none' },
    ]
  }

  function friendlyActionSummary(ann) {
    const { t } = useI18n({})
    // const annStore = AnnStore()
    const { formatSeconds } = TimeFormatter()
    let arg = ''
    switch (ann.click_action) {
      case 'link':
        return t('common.open_link') + ann.click_value
      case 'jump':
        return t('common.change_time') + formatSeconds(ann.click_value)
      case 'video':
        return t('common.switch_video') + annStore.findVideo(ann.click_value)?.title
      case 'message':
        return t('common.show_message') + ': ' + ann.click_value
      case 'article':
        return t('common.show_article') + ': ' + annStore.findArticle(ann.click_value)?.title
      case 'view':
        return t('common.show_image') + ': ' + annStore.findImage(ann.click_value)?.title
      case 'cart':
        return t('common.add_to_cart')
      case 'clip':
        return t('common.play_video_clip') + ': ' + annStore.findSource(ann.click_value)?.title
      case 'audio':
        return t('common.plays_audio_clip') + ': ' + annStore.findAudio(ann.click_value)?.title
      case 'email':
        return t('common.start_email')
      case 'phone':
        return t('common.start_call')
      case 'modal':
        return t('common.open_link_modal') + ': ' + ann.click_value
      case 'collect':
        return t('common.collect_response') + ': ' + ann.click_value
      case 'download':
        return t('common.download_file') + ': ' + annStore.findFile(ann.click_value)?.title
      case 'show_card':
        return t('common.show_card')
      case 'get':
        // TODO nix these?
        return t('common.connector_get_request')
      case 'post':
        // TODO nix these?
        return t('common.connector_post_request')
      case 'none':
        return t('common.none')
      case 'back':
        return t('common.goes_back')
      case 'genie':
        return t('common.open_genie_ai')
      case 'openai':
        return t('common.ask_chatgpt')
      case 'resourceTray':
        return t('common.open_magic_menu')
      default:
        return t('common.none')
    }
  }

  function actionToPrompt(action) {
    const { t } = useI18n({})

    switch (action) {
      case 'link':
        return t('common.website_link')
      case 'jump':
        return t('common.select_time')
      case 'video':
        return t('common.select_video')
      case 'message':
        return t('common.enter_message')
      case 'article':
        return t('common.select_article')
      case 'view':
        return t('common.select_image')
      case 'cart':
        return t('common.select_product')
      case 'clip':
        return t('common.select_clip')
      case 'audio':
        return t('common.select_audio')
      case 'email':
        return t('common.enter_email')
      case 'phone':
        return t('common.enter_phone')
      case 'modal':
        return t('common.enter_link')
      case 'collect':
        return t('common.enter_prompt')
      case 'download':
        return t('common.select_file')
      case 'show_card':
        return t('common.show_card')
      case 'get':
        // TODO nix these?
        return t('common.get')
      case 'post':
        // TODO nix these?
        return t('common.post')
      case 'none':
        return t('common.none')
      case 'back':
        return t('common.back')
      case 'genie':
        return t('common.genie_ai')
      case 'openai':
        return t('common.enter_prompt')
      case 'resourceTray':
        return t('common.magic_menu')
      default:
        return t('common.none')
    }
  }

  function animationToWord(animation) {
    const { t } = useI18n({})
    switch (animation) {
      case 'pulse_v1':
        return t('common.pulse')
      case 'gelatine_v1':
        return t('common.gelatine')
      case 'spin':
        return t('common.spin')
      case 'elastic-spin':
        return t('common.elastic_spin')
      case 'hithere_v1':
        return t('common.hithere')
      case 'grow_v1':
        return t('common.grow')
      case 'bounce_v1':
        return t('common.bounce')
      case 'flip_v1':
        return t('common.flip')
      case 'shake_v1':
        return t('common.shake')
      case 'swing_v1':
        return t('common.swing')
      case 'wobble_v1':
        return t('common.wobble')
      case 'flash_v1':
        return t('common.flash')
      case 'jumbotron_v1':
        return t('common.jumbotron')
    }
  }
  function formatNumber(number, abbreviate = true) {
    if (number == undefined) {
      return 0
    }
    if (number >= 9999) {
      return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    } else if (number >= 1000) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return abbreviate ? number.toString() : number.toFixed(2)
    }
  }

  function formatDollars(amount) {
    return '$' + formatNumber(amount, false)
  }
  function formatCents(amount) {
    return '$' + formatNumber(amount / 100, false)
  }

  function questionTypeToWord(type) {
    const { t } = useI18n({})
    const questionTypes = [
      { label: t('common.free_response'), value: 'free' },
      { label: t('common.multiple_choice'), value: 'multiple' },
      { label: t('common.choose_all_that_apply'), value: 'all' },
      { label: t('common.dropdown_select'), value: 'select' },
      { label: t('common.number_response'), value: 'number' },
      { label: t('common.date_response'), value: 'date' },
      { label: t('common.video_response'), value: 'video' },
      { label: t('common.voice_response'), value: 'voice' },
      { label: t('common.drawing_response'), value: 'draw' },
      { label: t('common.image_response'), value: 'image' },
      { label: t('common.rating_1_5'), value: 'rating' },
      { label: t('common.likert_scale'), value: 'likert' },
      { label: t('common.display_poll'), value: 'poll' }
    ]
    const questionType = questionTypes.find(q => q.value == type)
    return questionType ? questionType.label : t('common.none')
  }

  function conditionalSummary(ann, full = true) {
    const { t } = useI18n({})
    let str = ''
    if (ann.is_conditional) {
      // Full controls whether we show the "show if" or "hide if"
      if (full) {
        if (ann.conditional_show) {
          str += t('common.show_if') + ' '
        }
        if (ann.conditional_hide) {
          str += t('common.hide_if') + ' '
        }
      }
      str += capitalizeWords(ann.conditional_variable) + ' '
      str += ann.conditional_assertion + ' '
      str += ann.conditional_value + ' '

      if (ann.is_conditional_2) {
        str += ' '
        str += ann.conditional_operator == 0 ? t('common.and_capital') : t('common.or_capital')
        str += ' '
        str += capitalizeWords(ann.conditional_variable_2) + ' '
        str += ann.conditional_assertion_2 + ' '
        str += ann.conditional_value_2 + ' '
      }
    }
    return str
  }


  // Used for conditional logic
  function numberGreaterThan(num1, num2) {
    console.log("🔎 StringComposer numberGreaterThan", num1, num2)
    return !isNaN(num1) && !isNaN(num2) && parseFloat(num1) > parseFloat(num2)
  }

  function numberLessThan(num1, num2) {
    console.log("🔎 StringComposer numberLessThan", num1, num2)
    return !isNaN(num1) && !isNaN(num2) && parseFloat(num1) < parseFloat(num2)
  }

  function containsIgnoreCase(str1, str2) {
    console.log("🔎 StringComposer containsIgnoreCase", str1, str2)
    if (!str1 || !str2) return false
    return (str1 || '').toString().toLowerCase().includes((str2 || '').toString().toLowerCase())
  }


  function equalsIgnoreCase(str1, str2) {
    console.log("🔎 StringComposer equalsIgnoreCase", str1, str2)
    if (!str1 || !str2) return false
    return str1.toString().toLowerCase() === str2.toString().toLowerCase()
  }

  function isType(ann, type) {
    return {
      button: ann.type_of == 'button',
      link: ann.type_of == 'link',
      text: ann.type_of == 'text',
      image: ann.type_of == 'image',
      video: ann.type_of == 'video',
      audio: ann.type_of == 'audio',
      article: ann.type_of == 'article',
      cart: ann.type_of == 'cart',
      question: ann.type_of == 'question',
      hotspot: ann.type_of == 'hotspot',
    }
  }

  function countryToEmoji(country) {
    const { t } = useI18n({})
    switch (country) {
      case 'United States':
        return '🇺🇸'
      case 'Canada':
        return '🇨🇦'
      case 'United Kingdom':
        return '🇬🇧'
      case 'Australia':
        return '🇦🇺'
      case 'Germany':
        return '🇩🇪'
      case 'France':
        return '🇫🇷'
      case 'Italy':
        return '🇮🇹'
      case 'Japan':
        return '🇯🇵'
      case 'China':
        return '🇨🇳'
      case 'Brazil':
        return '🇧🇷'
      case 'India':
        return '🇮🇳'
      case 'Mexico':
        return '🇲🇽'
      case 'Russia':
        return '🇷🇺'
      case 'South Korea':
        return '🇰🇷'
      case 'Turkey':
        return '🇹🇷'
      case 'Spain':
        return '🇪🇸'
      case 'Argentina':
        return '🇦🇷'
      case 'Chile':
        return '🇨🇱'
      case 'Colombia':
        return '🇨🇴'
      case 'Peru':
        return '🇵🇪'
      case 'Indonesia':
        return '🇮🇩'
      case 'Pakistan':
        return '🇵🇰'
      case 'Nigeria':
        return '🇳🇬'
      case 'South Africa':
        return '🇿🇦'
      case 'Egypt':
        return '🇪🇬'
      case 'Nigeria':
        return '🇳🇬'
      case 'Kenya':
        return '🇰🇪'
      case 'South Africa':
        return '🇿🇦'
      case 'Nigeria':
        return '🇳🇬'
      case 'Kenya':
        return '🇰🇪'
      default:
        return ''
    }
  }


  return {
    countryToEmoji,
    friendlyActionSummary,
    clickActions,
    isType,
    numberGreaterThan,
    numberLessThan,
    equalsIgnoreCase,
    containsIgnoreCase,
    formatDollars,
    formatCents,
    actionToPrompt,
    animationToWord,
    capitalizeWords,
    truncate,
    getFileType,
    toUpper,
    toLower,
    getFileName,
    getS3Key,
    getFileNameWithoutExtension,
    getFileNameWithExtension,
    objectToQueryString,
    formatNumber,
    transformContent,
    fixUrl,
    annTypeToWord,
    needsClickValue,
    conditionalSummary,
    questionTypeToWord,
    uppercase,
    lowercase,
  };
}
