import { ref } from "vue";
import { useToast } from "primevue/usetoast";


export function UxComposer() {

  function focus(id, delay=10) {
    console.log("🔎 focus", id, document.getElementById(id))
    setTimeout(() => {
      // console.log("🔎 focus", id, document.getElementById(id))
      document.getElementById(id)?.focus()
    }, delay)
  }

  // function makeToast(message, severity='info', life=1000, delay=10) {
  //   setTimeout(() => {
  //     toast.add({ title: t(message), severity: severity, life: life })
  //   }, delay)
  // }

  function focusAndSelect(id, delay=10) {
    console.log("🔎 focusAndSelect", id, document.getElementById(id))
    focus(id, delay)
    setTimeout(() => {
      document.getElementById(id).select()
      // console.log("🔎 focusAndSelect", id, document.getElementById(id))
    }, delay)
  }

  function highlightBox(id, delay=10, length=2000) {
    setTimeout(() => {
      let el = document.getElementById(id)
      if (el) {
        el.classList.add('focusHighlightBox')
        setTimeout(() => {
          el.classList.remove('focusHighlightBox')
        }, length)
      }
    }, delay)
  }

  function focusAndHighlight(id, delay=10, length=2000, error=false) {
    console.log("🔎 focusAndHighlight", id, document.getElementById(id))
    focus(id, delay)
    setTimeout(() => {
      let el = document.getElementById(id)
      if (el) {
        el.classList.add(error ? 'focusErrorHighlight' : 'focusHighlight')
        setTimeout(() => {
          el.classList.remove(error ? 'focusErrorHighlight' : 'focusHighlight')
        }, length)
      }
    }, delay)
  }

  function toggleAutocomplete(id, on, delay=10) {
    setTimeout(() => {
      // console.log("🌜 toggleAutocomplete", id, on, document.getElementById(id))
      document.getElementById(id) && (document.getElementById(id).autocomplete = on ? "on" : "off")
    }, delay)
  }
  function toggleAutocompleteByElement(element, on, delay=10) {
    setTimeout(() => {
      element.autocomplete = on ? "on" : "off"
    }, delay)
  }

  return { focus, focusAndSelect, focusAndHighlight, toggleAutocomplete, toggleAutocompleteByElement, highlightBox };
}