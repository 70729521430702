import { AnnStore } from '@/front/stores/ann_store.js'
import { PlayerStore } from '@/front/stores/player_store.js'
import { PlaybackStore } from '@/front/stores/playback_store.js'
import { VideoStore } from '@/front/stores/video_store.js'
import { ClickStore } from '@/front/stores/click_store.js'
import { AudioStore } from '@/front/stores/audio_store.js'
import { AssetStore } from '@/front/stores/asset_store.js'
import { ClipStore } from '@/front/stores/clip_store.js'
import { useRouter } from 'vue-router'
import { StringComposer } from '@/front/composables/StringComposer.js'
import { BrowserHelper } from '@/front/composables/BrowserHelper.js'
const { fixUrl } = StringComposer()
const { browserInfo } = BrowserHelper()
import { UxComposer } from '@/front/composables/UxComposer.js'
const { focus } = UxComposer()
import { VideoService } from '@/front/services'
import { MindscriptService } from '@/front/services'
import { LeadCaptureStore } from '@/front/stores/lead_capture_store.js'


export function ClickComposer() {
  const router = useRouter()
  const annStore = AnnStore()
  const assetStore = AssetStore()
  const playerStore = PlayerStore()
  const playbackStore = PlaybackStore()
  const videoStore = VideoStore()
  const clickStore = ClickStore()
  const audioStore = AudioStore()
  const leadCaptureStore = LeadCaptureStore()

  function trackClick(ann) {
    // TODO track the click
    console.log("⭐️ ClickComposer trackClick", ann)
    playbackStore.safeTrackViewerClick(videoStore.video, ann)
  }

  function doVariableEvaluation(variable, value) {
    console.log("⭐️ ClickComposer doVariableEvaluation", variable, value)
    if (variable && value) {
      if (value.startsWith("{{") && value.endsWith("}}")) {
        let result = MindscriptService.evaluateExpression(value, playbackStore)
        console.log("⭐️ ClickComposer doVariableEvaluation result", result)
        playbackStore.setVariable(variable, result)
      } else {
        console.log("⭐️ ClickComposer doVariableEvaluation no expression", value)
        playbackStore.setVariable(variable, value)
      }
    }
  }

  function submitResponse(ann, response) {
    console.log("⭐️ ClickComposer submitResponse")
    leadCaptureStore.handleInteractionClick(ann.id)
    doVariableEvaluation(ann.variable, response)
    playbackStore.safeTrackResponse(videoStore.video, ann, response)
  }

  function clickInteraction(ann, completeAction = true) {
    console.log("💟 ClickStore clickInteraction", ann)
    clickStore.clearTimedPause()
    leadCaptureStore.handleInteractionClick(ann.id)
    trackClick(ann)
    // Mark as clicked
    ann.clicked = true
    // Add to user clicks
    playbackStore.userClicks.push(ann.id)
    // Clear out paused interaction
    annStore.pausedInteraction = null

    doVariableEvaluation(ann.variable, ann.value)

    if (completeAction) {
      // Handle the click action unless skip explicitly
      doAction(ann)
    } else {
      console.log("⭐️ ClickComposer clickInteraction doAction false")
    }

    // console.log("⭐️ ClickComposer clickInteraction clearn up and reset active")
    // Remove from must click interactions
    annStore.resetMustClickInteractions()
    // Reset active anns
    annStore.resetActive()
    // Play if no more must click interactions
    if (annStore.mustClickInteractions.length === 0) {
    } else {
      console.log("⭐️ ClickComposer still must click interactions: " + annStore.mustClickInteractions.length)
    }
  }

  function doAction(ann) {
    console.log("doAction: ", ann.click_action, ann.click_value)
    switch (ann.click_action) {
      case 'video':
        let token = ann.click_value.split("---")[1]
        doVideoBranch(token)
        return;
      case 'message':
        showMessage(ann)
        return;
      case 'link':
        doOpenLink(ann)
        return;
      case 'jump':
        doJump(ann)
        return;
      case 'back':
        doVideoBranch(playbackStore.refer_video)
        return;
      case 'view':
        doImage(ann.click_value)
        return;
      case 'article':
        console.log("⭐️ ClickComposer clickInteraction article", ann.click_value)
        doArticle(ann)
        return;
      case 'genie':
        doGenie(ann)
        return;
      case 'openai':
        doChatGPT(ann)
        return;
      case 'email':
        doEmail(ann)
        return;
      case 'phone':
        doPhone(ann)
        return;
      case 'modal':
        startLink(ann.click_value)
        return;
      case 'collect':
        doCollect(ann)
        return;
      case 'download':
        doDownload(ann)
        return;
      case 'resourceTray':
        openMagicMenu(ann)
        return;
      case 'clip':
        doClip(ann)
        return;
      case 'audio':
        doAudio(ann)
        return;
      default:
        break
    }
  }

  async function doAudio(ann) {
    console.log("⭐️ ClickComposer doAudio", ann)
    playerStore.pause("clickComposer doAudio")
    clickStore.startAudio()
    audioStore.streamById(ann.click_value).then(() => {
      console.log("⭐️ ClickComposer doAudio stream success")
    })
  }

  function finishAudio() {
    clickStore.stopAudio()
    annStore.tryFinishQuestion()
  }

  async function doClip(ann) {

    const clipStore = ClipStore()
    // console.log("⭐️ ClickComposer doClip", ann)
    playerStore.pause("clickComposer doClip")
    // Set state first
    clickStore.startClip()
    // TODO change this for source?
    clipStore.streamById(ann.click_value).then(() => {
      console.log("⭐️ ClickComposer doClip stream success")
    })
  }

  function finishClip() {
    const clipStore = ClipStore()
    clickStore.stopClip()
    clipStore.clear()
    playerStore.play("clickComposer finishClip")
  }

  function openMagicMenu(ann) {
    // TODO pause or not setting
    playerStore.pause("clickComposer openMagicMenu")
    console.log("⭐️ ClickComposer openMagicMenu", ann)
    clickStore.showMagicMenu(ann.click_value)
  }

  function finishMagicMenu() {
    clickStore.hideMagicMenu()
    playerStore.play("clickComposer finishMagicMenu")
  }

  function openGuide(ann) {
    // TODO pause or not setting
    playerStore.pause("clickComposer openGuide")
    console.log("⭐️ ClickComposer openGuide", ann)
    clickStore.showGuide(ann.click_value)
    focus("guide-search", 250)
  }

  function finishGuide() {
    clickStore.hideGuide()
    playerStore.play("clickComposer finishGuide")
  }

  function doDownload(ann) {
    console.log("⭐️ ClickComposer doDownload", ann)
    if (browserInfo.isIOS) {
      // TODO
      playerStore.pause("clickComposer doDownload")
      safeOpenLink(ann.download_url, false)
    } else {
      downloadFile(ann.download_url)
    }
  }

  function downloadFileFromUrl(url, filename = null) {
    console.log("⭐️ ClickComposer downloadUrl", url)
    if (browserInfo.isIOS) {
      // TODO
      playerStore.pause("clickComposer doDownload")
      safeOpenLink(ann.download_url, false)
    } else {
      downloadFile(url, filename)
    }
  }

  function doChatGPT(ann) {
    console.log("⭐️ ClickComposer doChatGPT", ann)
    playerStore.pause("clickComposer doChatGPT")
    clickStore.startChatGPT()
  }

  function finishChatGPT() {
    clickStore.stopChatGPT()
    playerStore.play("clickComposer finishChatGPT")
  }

  function doGenie(ann) {
    console.log("⭐️ ClickComposer doGenie", ann)
    playerStore.pause("clickComposer doGenie")
    clickStore.startGenie()
  }

  function finishGenie() {
    clickStore.stopGenie()
    playerStore.play("clickComposer finishGenie")
  }

  function doCollect(ann) {
    console.log("⭐️ ClickComposer doCollect", ann)
    playerStore.pause("clickComposer doCollect")
    clickStore.startCollect(ann.click_value)
  }

  function finishCollect() {
    clickStore.stopCollect()
    playerStore.play("clickComposer finishCollect")
  }

  function doJump(ann) {
    clickStore.resetAll()
    console.log("⭐️ ClickComposer doJump", ann.click_value, ann)
    playerStore.seekAndPlay(ann.click_value)
    // TODO
  }

  function doImage(url) {
    playerStore.pause("clickComposer doImage")
    console.log("⭐️ ClickComposer doImage", url)
    clickStore.setActiveImageUrl(url)
  }

  function finishImage() {
    clickStore.clearActiveImageUrl()
    playerStore.play("clickComposer finishImage")
  }

  function doArticle(ann) {
    playerStore.pause("clickComposer doArticle")
    console.log("⭐️ ClickComposer doArticle", ann.click_value)
    assetStore.getArticle(ann.click_value).then(article => {
      clickStore.setActiveArticle(article)
    })
  }

  function showMessage(ann) {
    console.log("⭐️ ClickComposer showMessage", ann.click_value)
    playerStore.pause("clickComposer showMessage")
    // Set the message
    clickStore.setActiveMessage(ann.click_value)
  }

  function finishMessage() {
    clickStore.clearActiveMessage()
    annStore.tryFinishQuestion()
  }

  function finishArticle() {
    clickStore.clearActiveArticle()
    playerStore.play("clickComposer finishArticle")
  }

  function startLink(url) {
    clickStore.startLink(url)
  }

  function finishLink() {
    clickStore.stopLink()
    annStore.resetActive()
    playerStore.play("clickComposer finishLink")
  }

  function doEmail(ann) {
    console.log("⭐️ ClickComposer doEmail", ann.click_value)
    playerStore.pause("clickComposer doEmail")
    let url = "mailto:" + ann.click_value
    console.log("⭐️ ClickComposer doEmail url", url)
    safeOpenLink(url, false)
  }

  function doPhone(ann) {
    console.log("⭐️ ClickComposer doPhone", ann.click_value)
    playerStore.pause("clickComposer doPhone")
    let url = "tel:" + ann.click_value
    console.log("⭐️ ClickComposer doPhone url", url)
    safeOpenLink(url, false)
  }

  function doOpenLink(ann) {
    playerStore.pause("clickComposer doOpenLink")
    safeOpenLink(ann.click_value, false)
  }

  function doVideoBranch(token) {
    clickStore.resetAll()
    VideoService.branchToVideo(token, {
      router,
      player: playerStore,
      playback: playbackStore,
      video: videoStore
    })
  }

  function safeOpenLink(url, sameTab = false) {
    console.log("⭐️ ClickComposer safeOpenLink", url, sameTab)
    // Validate the URL parameter
    if (typeof url !== 'string' || !url.trim()) {
      console.error('openLink: Invalid URL provided.');
      return false;
    }

    url = fixUrl(url)

    // Optional: Further URL validation using URL constructor
    try {
      new URL(url);
    } catch (e) {
      console.error('openLink: Malformed URL.');
      return false;
    }

    console.log("⭐️ ClickComposer safeOpenLink final url", url)

    if (sameTab) {
      // Redirect in the same tab
      window.location.href = url;
      return true;
    } else {
      // Open in a new tab with security best practices
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');

      if (newWindow) {
        // For older browsers that do not support 'noopener'
        newWindow.opener = null;
        return true;
      } else {
        // Fallback: If window.open was blocked (e.g., by a pop-up blocker)
        console.warn('openLink: Unable to open a new tab. Pop-up blocker might be preventing this action.');
        return false;
      }
    }
  }

  /**
 * Downloads a file from a given URL to the user's local device.
 *
 * @param {string} url - The direct URL to the file stored in Amazon S3.
 * @param {string} [filename] - Optional. The desired name for the downloaded file.
 *                               If not provided, the filename is extracted from the URL.
 * @returns {Promise<void>} - A promise that resolves when the download is initiated.
 */
  async function downloadFile(url, filename = null) {
    console.log("⭐️ ClickComposer downloadFile", url, filename)
    try {
      // Validate the URL
      if (typeof url !== 'string' || !url.trim()) {
        throw new Error('Invalid URL provided.');
      }

      // Extract filename from URL if not provided
      if (!filename) {
        const urlParts = url.split('/');
        filename = urlParts[urlParts.length - 1].split('?')[0]; // Remove query params if any
        if (!filename) {
          throw new Error('Unable to determine filename from URL.');
        }
      }

      // Fetch the file as a blob
      const response = await fetch(url, {
        method: 'GET',
        // headers: {
        //   'Content-Type': 'application/octet-stream',
        // },
        mode: 'cors', // Ensure CORS is enabled
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch the file. Status: ${response.status}`);
      }

      const blob = await response.blob();

      // For IE and Edge (legacy), use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
        return;
      }

      // Create a blob URL
      const blobUrl = window.URL.createObjectURL(blob);

      // Create a temporary anchor element
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.style.display = 'none';

      // Trigger the download
      a.click();

      // Cleanup
      a.remove();
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Download failed:', error);
      alert(`Failed to download the file: ${error.message}`);
      throw error;
    }
  }

  return {
    clickInteraction,
    doVideoBranch,
    finishMessage,
    finishArticle,
    finishImage,
    safeOpenLink,
    finishChatGPT,
    finishGenie,
    finishCollect,
    downloadFile,
    finishClip,
    finishAudio,
    doAction,
    openMagicMenu,
    finishMagicMenu,
    openGuide,
    finishGuide,
    downloadFileFromUrl,
  }
}
