<template>
  <div class="grid grid-cols-1">
     <Insights />
  </div>
</template>

<script setup>  
import { useRoute } from 'vue-router'
const router = useRouter()
const location = useRoute()
import Insights from '@/front/components/Insights.vue'
</script>
