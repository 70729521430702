// front.js
import { createApp } from 'vue';
import Router from '@/front/routes.js';
import Main from '@/front/views/shared/Main.vue';
import Axios from "axios";

const app = createApp(Main);

// API + Axios wrapper
import { createApi } from '@/plugins/api';
const Api = createApi({ handler: Axios, namespace: '' });

// Pinia + API setup  
import { createPinia } from 'pinia';
const Pinia = createPinia();
Pinia.use(({ store }) => { 
 store.Api = Api;
 store.router = Router;
})

import PrimeVue from 'primevue/config';
import Lara from '@/front/presets/lara';      //import preset 
import VuePapaParse from 'vue-papa-parse'
// import Tooltip from 'primevue/tooltip';
import FloatingVue from 'floating-vue'
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';

// I18n loader
import { createI18n } from 'vue-i18n';
const I18n = createI18n({ locale: 'current', messages: translations, legacy: false });

app.use(Router)
 .use(Pinia)
 .use(I18n)
 .use(Api)
 .use(VuePapaParse)

app.use(PrimeVue, {
 pt: Lara,
 unstyled: true,
}).use(ConfirmationService)
.use(ToastService);

import 'floating-vue/dist/style.css'


app.component('Toast', Toast);
app.component('ConfirmDialog', ConfirmDialog);

// app.directive('tooltip', Tooltip);
app.use(FloatingVue, {
  theme: 'light',
  placement: 'top',
  distance: 10,
  tether: true,
});

app.mount('#app')