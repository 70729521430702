import { defineStore } from 'pinia'
import { AnnStore } from '@/front/stores/ann_store.js';
import { ClickStore } from '@/front/stores/click_store.js';
import { LeadCaptureStore } from '@/front/stores/lead_capture_store.js';
import { VideoService } from '@/front/services/VideoService.js';
import { VideoStore } from '@/front/stores/video_store.js';
import { PlaybackStore } from '@/front/stores/playback_store.js';
import { MessageComposer } from '@/front/composables/MessageComposer.js';


export const PlayerStore = defineStore('player', {
  state: () => {
    return {
      ready: false,
      player: null,
      decitime: 0.0,
      fulltime: 0, // Rounded
      started: false,
      initialized: false,
      playerState: {},
      currentScreenshot: null,
      screenToVideoRatio: 1.0,
      layerStyle: {
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
      },
      layerDimensions: {
        top: 0,
        left: 0,
        height: 100,
        width: 100,
      },
      videoDimensions: {
        width: 0,
        height: 0,
      },
      videoElement: null,
      autoplayUnmuted: true,
      activeSrc: null,
      branching: false,
      branchTransition: null,
      activeTracks: []
    }
  },

  actions: {
    async takeScreenshot() {
      return VideoService.takeVideoScreenshot(this.videoElement).then((dataUrl) => {
        // console.log("🌜 PlayerStore takeScreenshot", dataUrl)
        this.currentScreenshot = dataUrl
        return dataUrl
      })
    },
    async handleTimeUpdate(time) {
      const annStore = AnnStore();

      // console.log("PlayerStore handleTimeUpdate ", time)
      const currentDecitime = Math.round(time * 10) / 10;

      // Check if the decitime has changed since the last update
      if (currentDecitime !== this.decitime) {
        const newFulltime = Math.floor(time)
        if (newFulltime !== this.fulltime) {
          const { emitMessage } = MessageComposer(); 
          this.fulltime = newFulltime
          emitMessage("time_update", this.fulltime)
        }
        // Decitime has changed, process the interaction map
        // Calcualte if we seeked elsewhere, reset if so, otherwise normal playback is 0.1 more
        let needReset = (currentDecitime * 10 - this.decitime * 10 !== 1)
        annStore.clearDoneAt(this.decitime)
        this.decitime = currentDecitime;
        console.log("🕒 " + this.decitime)
        if (annStore.isEditing) {
          console.log("💟 PlayerStore handleTimeUpdate isEditing, pausing")
          this.pause("playerStore handleTimeUpdate isEditing")
          return
        }
        if (needReset) {
          annStore.resetActive(currentDecitime)
        } else {
          annStore.updateActive(currentDecitime);
        }

        // Update the last decitime to the current one
        // console.log("\n🕔", this.decitime)
      }
    },
    attachVideoElement() {
      this.videoElement = document.querySelector('#media-provider video')
      // console.log("🌜 PlayerStore attachVideoElement videoElement", this.videoElement)
    },
    setPlayerState(state) {
      this.playerState = state
      this.attachVideoElement()
      this.updateRatios()
    },
    toggleMute(muted) {
      this.videoElement ? this.videoElement.muted = muted : console.log("💦 PlayerStore toggleMute no videoElement")
      // this.videoElement.volume = muted ? 0.0 : 1.0
    },
    async seekAndPlay(time, timeout = 5000) {
      console.log("🌜 PlayerStore seekAndPlay", time)
      const annStore = AnnStore();

      try {
        await new Promise((resolve, reject) => {
          // Ensure we have video element
          this.attachVideoElement()
          // if (!this.videoElement) {
          //   reject(new Error('No video element found'))
          //   return
          // }

          const seekedHandler = () => {
            this.videoElement?.removeEventListener('seeked', seekedHandler)
            console.log("✅ PlayerStore seekAndPlay - seek completed")
            resolve()
          }

          this.videoElement?.addEventListener('seeked', seekedHandler)

          // Use existing seek logic
          if (this.videoElement) {
            this.videoElement.currentTime = time
          } else {
            console.log("💦 PlayerStore seekAndPlay no videoElement", this.player)
            this.player.currentTime = time
          }
          console.log("💟 PlayerStore seekAndPlay resetting active", time)
          annStore.resetActive(time)

          // Add timeout
          setTimeout(() => {
            this.videoElement?.removeEventListener('seeked', seekedHandler)
            reject(new Error('Seek timeout after ' + timeout + 'ms'))
          }, timeout)
        })

        // After successful seek, play the video
        console.log("🎬 PlayerStore seekAndPlay - starting playback")
        await this.play("seekAndPlay")

      } catch (error) {
        console.error("🔴 PlayerStore seekAndPlay error:", error)
        throw error
      }
    },

    // Modify your existing seek method to be async for consistency
    async seek(time) {
      console.log("🌜 PlayerStore seek", time)
      const annStore = AnnStore();

      try {
        await new Promise((resolve, reject) => {
          this.attachVideoElement()
          // if (!this.videoElement) {
          //   reject(new Error('No video element found'))
          //   return
          // }

          const seekedHandler = () => {
            this.videoElement?.removeEventListener('seeked', seekedHandler)
            resolve()
          }

          this.videoElement?.addEventListener('seeked', seekedHandler)
          let t = Math.min(Math.max(0, time), this.playerState.duration - 0.1)
          if (this.videoElement) {
            this.videoElement.currentTime = t
          } else {
            console.log("💦 PlayerStore seek no videoElement", this.player)
            this.player.currentTime = t
          }

          setTimeout(() => {
            this.videoElement?.removeEventListener('seeked', seekedHandler)
            reject(new Error('Seek timeout'))
          }, 5000)
        })

        console.log("💟 PlayerStore seek resetting active", time)
        annStore.resetActive(time)
      } catch (error) {
        console.error("🔴 PlayerStore seek error:", error)
        throw error
      }
    },
    togglePlayer() {
      console.log("PlayerStore togglePlayer")
      const annStore = AnnStore();
      const clickStore = ClickStore()
      const playbackStore = PlaybackStore()
      this.started = true
      playbackStore.beginTracking()

      // Check if pausing until interaction click
      if (annStore.pausedInteraction) {
        if (annStore.pausedInteraction.exact_time == this.decitime) {
          console.log(annStore.pausedInteraction.exact_time, this.decitime)
          console.log("💟 PlayerStore togglePlayer pausedInteraction returning", annStore.pausedInteraction)
          return
        } else {
          console.log("💟 PlayerStore togglePlayer pausedInteraction not at time, resetting")
          annStore.pausedInteraction = null
        }
      }

      // Check if any must-click interactions on screen
      if (annStore.mustClickInteractions.length > 0) {
        console.log("💟 PlayerStore togglePlayer mustClickInteractions returning", annStore.mustClickInteractions)
        return
      }

      // Clear pause for timed pause
      clickStore.finishTimedPause()

      // Check if autoplaying
      if (!this.autoplayUnmuted) {
        this.toggleMute(false)
        this.autoplayUnmuted = true
        return
      }
      if (this.playerState.seeking) {
        return
      }
      if (annStore.isEditing) {
        this.pause("playerStore togglePlayer isEditing")
        return
      }
      if (this.playerState.paused) {
        this.playerState.paused = false
        this.play("playerStore togglePlayer")
      } else {
        this.playerState.paused = true
        this.pause("playerStore togglePlayer")
      }
    },
    play(from) {
      console.log("🌜 🌜 🌜 🌜 🌜 🌜 🌜 🌜 PlayerStore play", from)
      const clickStore = ClickStore();
      const annStore = AnnStore();
      const leadCaptureStore = LeadCaptureStore();
      this.attachVideoElement()
      // Check lead capture triggers
      if (leadCaptureStore.checkTriggers(this.decitime)) {
        console.log("💟 PlayerStore play checkTriggers returning", leadCaptureStore.checkTriggers(this.decitime))
        return
      } else if (clickStore.hasActiveDialog) {
        // Showing dialog that means we should not play
        console.log("💟 PlayerStore play hasActiveDialog returning", clickStore.hasActiveDialog)
        return
      }
      annStore.resetActive(this.decitime)
      annStore.updateActive(this.decitime)
      if (this.videoElement) {
        this.videoElement.play()
      } else {
        console.log("💦 PlayerStore play no videoElement", this.player)
        this.player.play()
      }
    },
    pause(from) {
      console.log("🌜 🌜 🌜 🌜 🌜 🌜 🌜 🌜 PlayerStore pause", from)
      this.attachVideoElement()
      if (this.videoElement) {
        this.videoElement.pause()
      } else {
        console.log("💦 PlayerStore pause no videoElement", this.player)
        this.player.pause()
      }
    },
    clipFinished() {
      console.log("🌜 PlayerStore clipFinished")
      // TODO decide if we want this
      this.play("playerStoreclipFinished")
    },
    updateRatios() {
      // Container
      const containerWidth = this.playerState.width
      const containerHeight = this.playerState.height
      const containerRatio = containerWidth / containerHeight
      // Video
      const videoWidth = this.videoElement?.videoWidth || containerWidth
      const videoHeight = this.videoElement?.videoHeight || containerHeight
      const videoRatio = videoWidth / videoHeight
      // Ratio between container and video
      this.screenToVideoRatio = (containerRatio / videoRatio)
      let width = 100
      let height = 100
      let left = 0
      let top = 0
      if (containerRatio > videoRatio) {
        width = containerHeight * videoRatio
        height = containerHeight
      } else {
        width = containerWidth
        height = containerWidth / videoRatio
      }
      left = (containerWidth - width) / 2
      top = Math.max(0, (containerHeight - height) / 2)
      if (isNaN(height)) {
        // height = window.innerHeight
        height = 0
      }

      left = Number(left.toFixed(1))
      top = Number(top.toFixed(1))
      width = Number(width.toFixed(1))
      height = Number(height.toFixed(1))
      this.videoDimensions = {
        width: videoWidth,
        height: videoHeight,
      }
      this.layerStyle = {
        width: `${width}px`,
        height: `${height}px`,
        left: `${left}px`,
        top: `${top}px`,
      }
      this.layerDimensions = {
        top: top,
        left: left,
        height: height,
        width: width,
      }
    },
    enterFullscreen() {
      console.log("🌜 PlayerStore enterFullscreen")
      // this.attachVideoElement()
      // this.videoElement?.requestFullscreen()
      this.player.requestFullscreen()
    },
    exitFullscreen() {
      console.log("🌜 PlayerStore exitFullscreen")
      this.player.exitFullscreen()
    }
  },
  getters: {
    safeVideoElement() {
      return this.videoElement || this.player
    },
  }
})