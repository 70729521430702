import { defineStore } from 'pinia'
import { TimeFormatter } from '@/front/composables/TimeFormatter.js'
const { convertDecitimeToKey } = TimeFormatter();
import { PlayerStore } from '@/front/stores/player_store.js'
import { DesignStore } from '@/front/stores/design_store.js'
import { PlaybackStore } from '@/front/stores/playback_store.js'
import { UserStore } from '@/front/stores/user_store.js'
import { VideoStore } from '@/front/stores/video_store.js'
import { AudioStore } from '@/front/stores/audio_store.js'
import { ClipStore } from '@/front/stores/clip_store.js'
import { ClickStore } from '@/front/stores/click_store.js'
import { UxComposer } from '@/front/composables/UxComposer.js'
const { focusAndHighlight, focusAndSelect } = UxComposer()
import { StringComposer } from '@/front/composables/StringComposer.js'
const { transformContent, numberGreaterThan, numberLessThan, equalsIgnoreCase, containsIgnoreCase } = StringComposer()
import { ClickComposer } from '@/front/composables/ClickComposer.js'
import { LinkValidator } from '@/front/composables/LinkValidator.js'
const { isValidURL } = LinkValidator()
import { VideoService } from '@/front/services'
import { InteractionService } from '@/front/services'
import { LeadCaptureStore } from '@/front/stores/lead_capture_store.js'

import _ from 'lodash'; // To create a deep clone of the interaction object

export const AnnStore = defineStore('anns', {
  state: () => {
    return {
      anns: [],
      originalAnns: [], // Holds the original state of the annotations for bulk editing reversion
      loading: true,
      ogAnn: null, // holds the original copy of the interaction
      editAnn: null, // holds the current state of the interaction being edited
      isEditing: false, // flag to track if we are editing an interaction
      timeMap: {}, // map of all interactions by time
      active: [], // list of active interactions on layer
      hoverAnn: null, // holds the current state of the interaction being hovered over (NOT MARKER HOVER)
      markerAnn: null, // holds the current state of the interaction being hovered over (MARKER HOVER)
      pausedInteraction: null, // holds the current state of the interaction being paused,
      mustClickInteractions: [], // holds the current state of the interactions being must click,
      isBulkEditing: false,
      bulkEditInteractions: [],
      showImageSelector: false,
      showVideoSelector: false,
      showAudioSelector: false,
      doneInteractions: new Map(),
      activeQuestionQueue: [],
      imageLoading: false,
    }
  },

  actions: {
    addAnn(type_of) {
      console.log("🔎 AnnStore addAnn", type_of)
      const playerStore = PlayerStore()
      const videoStore = VideoStore()
      const finish = Math.min(Number((playerStore.decitime + 10).toFixed(1)), Number((playerStore.playerState.duration - 0.1).toFixed(1)))
      const font_size = Number((playerStore.videoDimensions.width / 33).toFixed(1))
      const ann = {
        type_of: type_of,
        exact_time: playerStore.decitime,
        exact_finish: finish,
        time: 0,
        content: '',
        isActive: true,
        pos_left: 35,
        pos_top: 41.5,
        pos_width: 30,
        pos_height: 17,
        click_action: null,
        click_value: null,
        border_color: null,
        border_width: null,
        border_radius: 10,
        background_color: null,
        text_color: null,
        font_size: font_size,
        og_video_width: playerStore.videoDimensions.width,
        og_video_height: playerStore.videoDimensions.height,
        token: videoStore.video.token
      }

      // For button, hotspot, etc set specific styles
      this.setSpecificStyles(ann)
      this.setDefaultValues(ann)
      this.anns.push(ann)
      this.setMap()
      this.editAnn = ann
      this.isEditing = true
      console.log("🔎 focusAndHighlight", 'edit-ann-content')

      // For interactions that only show at one time, e.g. questions and navs, set the finish time to one second after the show time
      if (!this.allNeeds.needsHideTime) {
        this.editAnn.exact_finish = ann.exact_time + 0.1
      }

      if (ann.type_of == 'image') {
        this.showImageSelector = true
      } else if (ann.type_of == 'video') {
        this.showVideoSelector = true
      } else if (ann.type_of == 'audio') {
        this.showAudioSelector = true
      } else if (ann.type_of == 'question') {
        const clickStore = ClickStore()
        clickStore.showQuestion(ann)
      } else if (ann.type_of == 'drawing') {
        const clickStore = ClickStore()
        clickStore.startDrawing()
      } else {
        setTimeout(() => {
          focusAndHighlight('edit-ann-content')
        }, 100)
      }
    },
    setDefaultValues(ann) {
      const designStore = DesignStore()
      switch (ann.type_of) {
        case 'button':
          ann.click_action = 'link'
          break;
        case 'hotspot':
          ann.click_action = 'link'
          break;
        case 'text':
          ann.click_action = 'none'
          break;
        case 'image':
          ann.click_action = 'none'
          break;
        case 'jump':
          ann.click_action = 'jump'
          ann.click_value = 0
          break;
        case 'redirect':
          ann.click_action = 'redirect'
          break;
        case 'drawing':
          ann.timer = 10
          break;
        case 'pause':
          ann.click_action = 'pause'
          ann.pause = true
          ann.timer = 10
          break;
        case 'question':
          ann.question_type = 'free'
          ann.question_prompt = ''
          ann.pos_top = designStore.design.question_top
          ann.pos_left = designStore.design.question_left
          ann.pos_width = designStore.design.question_width
          ann.pos_height = designStore.design.question_height
          ann.background_color = designStore.design.question_background_color
          ann.text_color = designStore.design.question_text_color
          break;
      }
    },

    updateAllConditionalStatuses() {
      this.anns.forEach(ann => {
        this.setConditionalStatus(ann)
      })
      // Find all interactions that should be visible at current time
      const activeInteractions = []
      const playerStore = PlayerStore()
      const currentKey = convertDecitimeToKey(playerStore.decitime)
      Object.entries(this.timeMap).forEach(([key, value]) => {
        if (key <= currentKey) {
          value.show.forEach(ann => {
            // Check if this interaction spans the current time
            const hideKey = convertDecitimeToKey(ann.exact_finish)
            if (hideKey >= currentKey) {
              activeInteractions.push(ann)
            }
          })
          value.hide.forEach(ann => {
            const index = activeInteractions.findIndex(a => a.id === ann.id)
            if (index !== -1) {
              activeInteractions.splice(index, 1)
            }
          })
        }
      })

      // Re-evaluate each interaction that should be timing-wise visible
      activeInteractions.forEach(ann => {
        const isCurrentlyActive = this.active.some(a => a.id === ann.id)
        if (ann.visible && !isCurrentlyActive) {
          this.doShow(ann)
        } else if (!ann.visible && isCurrentlyActive) {
          this.doHide(ann)
        }
      })
    },

    getConditionalResult(variable, assertion, value) {
      // console.log("🔎 AnnStore getConditionalResult", variable, assertion, value)
      const playbackStore = PlaybackStore()
      const variableValue = playbackStore.getVariable(variable)
      let result = true
      switch (assertion) {
        case 'equals':
          result = equalsIgnoreCase(variableValue, value)
          break;
        case 'notequals':
          result = !equalsIgnoreCase(variableValue, value)
          break;
        case 'greaterthan':
          result = numberGreaterThan(variableValue, value)
          break;
        case 'lessthan':
          result = numberLessThan(variableValue, value)
          break;
        case 'exists':
          result = !!variableValue
          break;
        case 'notexists':
          result = !variableValue
          break;
        case 'contains':
          result = containsIgnoreCase(variableValue, value)
          break;
        case 'notcontains':
          result = !containsIgnoreCase(variableValue, value)
          break;
        case 'regex':
          // TODO
          result = variableValue.match(value)
          break;
      }
      console.log("🔎 AnnStore conditional", variable + "(" + variableValue + ")" + " " + assertion + " " + value, result)

      return result
    },
    setConditionalStatus(ann) {
      const playbackStore = PlaybackStore()
      if (playbackStore.manager || ann.hidden) {
        return
      }
      if (ann.is_conditional) {
        const result1 = this.getConditionalResult(ann.conditional_variable, ann.conditional_assertion, ann.conditional_value)
        console.log("🔎 AnnStore setConditionalStatus ", ann.content || ann.question_prompt, result1)
        if (ann.is_conditional_2) {
          const result2 = this.getConditionalResult(ann.conditional_variable_2, ann.conditional_assertion_2, ann.conditional_value_2)
          // console.log("🔎 AnnStore setConditionalStatus_2 result", result2)
          // 0 = AND, 1 = OR
          let visible = ann.conditional_operator == 0 ? (result1 && result2) : (result1 || result2)
          ann.visible = ann.conditional_show ? visible : !visible
        } else {
          // Only one conditional
          ann.visible = ann.conditional_show ? result1 : !result1
        }
      }

    },
    setSpecificStyles(ann) {
      const playerStore = PlayerStore()
      const videoStore = VideoStore()
      if (ann.type_of === 'button') {
        ann.pos_left = 40
        ann.pos_top = 41.5
        ann.pos_width = 30
        ann.pos_height = 17
        ann.background_color = videoStore.video.design.button_background_color
        ann.text_color = videoStore.video.design.button_text_color
        ann.border_color = videoStore.video.design.button_border_color
      } else if (ann.type_of === 'hotspot') {
        ann.background_color
        ann.pos_left = 40
        ann.pos_top = 40
        ann.pos_width = 30
        ann.pos_height = 30
        ann.background_color = videoStore.video.design.color
        ann.border_color = videoStore.video.design.color
        ann.border_radius = videoStore.video.design.border_radius
      } else if (ann.type_of === 'text') {
        ann.pos_left = 40
        ann.pos_top = 40
        ann.pos_width = 20
        ann.pos_height = 10
        ann.text_align = 'left'
        ann.content = 'Enter Text'
        ann.text_color = videoStore.video.design.comment_text_color
        ann.font_size = playerStore.videoDimensions.width / 50
        console.log("Set Text Color", ann.color)
        ann.background_color = videoStore.video.design.comment_background_color
        ann.border_radius = videoStore.video.design.comment_border_radius
        focusAndSelect('edit-ann-content')
      } else if (ann.type_of === 'image') {
        ann.pos_left = 40
        ann.pos_top = 40
        ann.pos_width = 30
        ann.pos_height = 30
      }
    },
    async setEditingImageAspectRatio() {
      if (this.editAnn.type_of === 'image' && this.editAnn.content) {
        return new Promise((resolve, reject) => {
          const img = new Image()
          
          img.onload = () => {
            // Get the video and layer dimensions
            const playerStore = PlayerStore()
            const videoWidth = playerStore.videoDimensions.width
            const videoHeight = playerStore.videoDimensions.height
            const layerWidth = playerStore.layerDimensions.width
            const layerHeight = playerStore.layerDimensions.height
            
            // Calculate aspect ratio
            const aspectRatio = img.width / img.height
            
            if (!this.editAnn.id) {
              // For new images, calculate dimensions and center
              const maxWidthPx = layerWidth * 0.3
              const maxHeightPx = layerHeight * 0.3
              
              // Determine dimensions that maintain aspect ratio within our max bounds
              let targetWidthPx, targetHeightPx
              if (maxWidthPx / aspectRatio <= maxHeightPx) {
                // Width is the limiting factor
                targetWidthPx = maxWidthPx
                targetHeightPx = maxWidthPx / aspectRatio
              } else {
                // Height is the limiting factor
                targetHeightPx = maxHeightPx
                targetWidthPx = maxHeightPx * aspectRatio
              }
              
              // Convert pixel values to percentages of video dimensions
              const targetWidth = (targetWidthPx / layerWidth) * 100
              const targetHeight = (targetHeightPx / layerHeight) * 100
              
              // Set dimensions and center the image
              this.editAnn.pos_width = targetWidth
              this.editAnn.pos_height = targetHeight
              this.editAnn.pos_left = (100 - targetWidth) / 2
              this.editAnn.pos_top = (100 - targetHeight) / 2
            }
            
            // Always set these properties regardless of new or existing
            this.editAnn.aspect_ratio = aspectRatio
            this.editAnn.original_width = img.width
            this.editAnn.original_height = img.height
            this.editAnn.og_video_width = videoWidth
            this.editAnn.og_video_height = videoHeight
            
            resolve({
              aspectRatio,
              width: this.editAnn.pos_width,
              height: this.editAnn.pos_height
            })
          }
    
          img.onerror = () => {
            reject(new Error('Failed to load image'))
          }
    
          img.src = this.editAnn.content
        })
      }
      return Promise.resolve(null)
    },
    startEdit(id) {
      const clickStore = ClickStore()
      const ann = this.anns.find(ann => ann.id === id)
      console.log("AnnStore startEdit " + ann.type_of, id)
      if (!ann) {
        console.log("AnnStore startEdit ann not found", id)
        return
      }
      console.log("🔎 AnnStore startEdit ann", ann)
      this.markAsDone(ann.exact_time, ann.id)
      this.ogAnn = _.cloneDeep(ann);
      console.log("ogAnn", this.ogAnn)
      this.editAnn = ann
      if (ann.type_of === 'image') {
        // TODO not working
        this.setEditingImageAspectRatio()
      } else if (ann.type_of === 'drawing') {
        clickStore.showDrawing(ann, false)
      }
      this.isEditing = true
      this.hoverAnn = null
      const playerStore = PlayerStore()
      console.log("go to time", this.editAnn.exact_time)
      // TODO timing
      // playerStore.player.currentTime = this.editAnn.exact_time
      if (!this.editAnn.isActive) {
        playerStore.seek(this.editAnn.exact_time)
        this.markAsDone(this.editAnn.exact_time, ann.id)
      }

      if (ann.type_of == 'video' && ann.content) {
        ClipStore().streamById(ann.content)
      } else if (ann.type_of == 'audio' && ann.content) {
        AudioStore().streamById(ann.content)
      } else if (ann.type_of == 'question') {
        const clickStore = ClickStore()
        clickStore.showQuestion(ann)
      }
    },

    startCopy(id, another = false) {
      // TOOD handle questions
      console.log("AnnStore startCopy", id)
      const ann = this.anns.find(ann => ann.id === id)
      if (!ann) {
        console.log("AnnStore startCopy ann not found", id)
        return
      }
      this.addAnn(ann.type_of)
      // Copy all attributes except ID from the source annotation
      Object.keys(ann).forEach(key => {
        if (key !== 'id' && key !== 'created_at' && key !== 'updated_at') {
          this.editAnn[key] = ann[key]
        }
      })
      if (another) {
        this.editAnn.content = ''
        this.editAnn.click_value = ''
        this.editAnn.pos_top = ann.pos_top + 5
        this.editAnn.pos_left = ann.pos_left + 5
      }
    },

    startBulkEdit(ids) {
      const playerStore = PlayerStore()
      playerStore.pause("AnnStore startBulkEdit")
      this.isBulkEditing = true
      this.bulkEditInteractions = ids
    },


    async doBulkDestroy(ids) {
      console.log("💦 AnnStore doBulkDestroy", ids)
      return this.Api.post(`/annotations/bulk_destroy`, { ids: ids }).then(() => {
        console.log("Bulk Destroy Finished")
      })
    },

    // TODO full annotations here
    async doBulkEdit(ids, attribute, value) {
      console.log("💦 AnnStore doBulkEdit", ids, attribute, value)
      return this.Api.post(`/annotations/bulk_edit`, { ids: ids, attribute: attribute, value: value }).then(() => {
        console.log("Bulk Edit Finished")
      })
    },

    async stopBulkEdit() {
      this.isBulkEditing = false
      this.bulkEditInteractions = []
      this.setMap()
    },

    async saveAndAddAnother(ann) {
      console.log("💦 AnnStore saveAndAddAnother", ann)
      this.saveAnn(ann).then((annotation) => {
        console.log("Saved ann, now starting copy", annotation)
        this.startCopy(annotation.id, true)
      })
    },

    async saveAnn(ann) {
      console.log("💦 AnnStore saveAnn", ann.id)
      // if (ann.pause && ann.exact_time == 0) {
      //   ann.exact_time = 0.1
      // }
      if (ann.exact_time) {
        ann.time = Math.floor(ann.exact_time)
        ann.finish = Math.floor(ann.exact_finish)
      }

      if (ann.id) {
        return this.updateAnn(ann)
      } else {
        return this.createAnn(ann)
      }
    },
    findAnnotationById(id) {
      return this.anns.find(ann => ann.id === id)
    },
    async createAnn(ann) {
      const playerStore = PlayerStore()
      console.log("💦 AnnStore createAnn", ann)
      return this.Api.post(`/annotations`, ann).then(response => {
        console.log("💦 AnnStore createAnn response", response)
        this.stopEditing()
        this.anns.push(response.data.annotation)
        this.setMap()
        playerStore.seek(response.data.annotation.exact_time)
        return response.data.annotation
      }).catch(error => {
        console.log("💦 AnnStore updateAnn error", error)
      })
    },
    async updateAnn(ann) {
      const playerStore = PlayerStore()
      const clickStore = ClickStore()
      console.log("💦 AnnStore updateAnn", ann)
      return this.Api.put(`/annotations/${ann.id}`, ann).then(response => {
        console.log("💦 AnnStore updateAnn response", response)
        const index = this.anns.findIndex(a => a.id === ann.id)
        if (index !== -1) {
          this.anns[index] = response.data.annotation
        }
        clickStore.resetAll()
        this.setMap()
        // TODO dont clear for prod
        this.clearEditAnn()
        playerStore.seek(response.data.annotation.exact_time)
        return response.data.annotation

        // this.setMap()
        console.log("Seek to ", response.data.annotation.exact_time)
      }).catch(error => {
        console.log("💦 AnnStore updateAnn error", error)
      })
    },

    async deleteAnn(id) {
      return this.Api.destroy(`/annotations/${id}`).then(response => {
        console.log("💦 AnnStore deleteAnn response", response)
        this.anns = this.anns.filter(item => item.id !== id);
        this.setMap()
        this.resetActive()
      })
    },

    stopEditing() {
      const ct = this.editAnn.exact_time
      if (this.editAnn.id) {
        const index = this.anns.findIndex(i => i.id === this.editAnn.id);
        if (index !== -1) {
          Object.assign(this.anns[index], this.ogAnn);
          this.editAnn = null; // Clear editing state
          this.ogAnn = null; // Clear original interaction copy
        }
      }
      this.clearEditAnn()
      this.updateActive(ct)
      this.anns = this.anns.filter(item => item.id !== undefined && item.id !== null);
      const clickStore = ClickStore()
      clickStore.resetAll()
    },

    clearEditAnn() {
      this.isEditing = false
      this.editAnn = null
      this.ogAnn = null
    },

    markAsDone(decitime, interactionId) {
      console.log("💟 AnnStore markAsDone", decitime, interactionId)
      if (!this.doneInteractions.has(decitime)) {
        this.doneInteractions.set(decitime, new Set())
      }
      this.doneInteractions.get(decitime).add(interactionId)
    },
    isDoneAt(decitime, interactionId) {
      // console.log("💟 AnnStore isDoneAt", decitime, interactionId, this.doneInteractions.get(decitime)?.has(interactionId) || false)
      return this.doneInteractions.get(decitime)?.has(interactionId) || false
    },
    clearDoneAt(decitime) {
      // console.log("💟 AnnStore clearDoneAt", decitime)
      this.doneInteractions.delete(decitime)
    },


    async index(path) {
      const playbackStore = PlaybackStore()
      const videoStore = VideoStore()
      this.resetState()
      this.loading = true
      return this.Api.get(`/annotations/${path || videoStore.video.token}`).then(response => {
        // console.log("AnnStore index response ", response)
        this.pagination = response.data.pagination;
        this.anns = response.data.annotations;
        this.originalAnns = _.cloneDeep(this.anns)

        // Only substitute variables if we are not managing the video
        if (!playbackStore.manager) {
          this.personalizeAllInteractions()
        }
        this.setMap()
        return response.data.annotations
      }).finally(() => {
        this.loading = false
      })
    },
    personalizeInteraction(ann) {
      // console.log("💟  AnnStore personalizeInteraction", ann.content)
      const playbackStore = PlaybackStore()
      // TODO
      ann.transformed_content = transformContent(ann.content, playbackStore.variables)
    },
    handleVariableChange() {
      this.personalizeAllInteractions()
      this.updateAllConditionalStatuses()
    },
    personalizeAllInteractions() {
      // console.log("💟  AnnStore personalizeAllInteractions: " + this.anns.length)
      this.anns.forEach(ann => {
        this.personalizeInteraction(ann)
      })
    },
    async setMap() {
      console.log("💦 AnnStore setMap")
      const playerStore = PlayerStore()
      this.timeMap = {}
      // sort anns by exact_time, then by created_at if exact_time is the same
      this.anns.sort((a, b) => {
        if (a.exact_time === b.exact_time) {
          // If either is a question, sort questions by created_at
          if (a.type_of === 'question' || b.type_of === 'question') {
            if (a.type_of === 'question' && b.type_of === 'question') {
              return new Date(a.created_at) - new Date(b.created_at);
            }
            // Questions take precedence
            return a.type_of === 'question' ? -1 : 1;
          }
          // For non-questions, use existing created_at sort
          return new Date(a.created_at) - new Date(b.created_at);
        }
        return a.exact_time - b.exact_time;
      });
      this.anns.forEach(ann => {
        this.setConditionalStatus(ann)
      })
      this.anns.filter(ann => !ann.tray_type && !ann.hidden).forEach(ann => {
        // console.log("💦 AnnStore setMap ann", ann)
        let startKey = convertDecitimeToKey(ann.exact_time)
        let endKey = convertDecitimeToKey(Number((ann.exact_finish + 0.1).toFixed(1)))
        // console.log("💦 AnnStore setMap ann", ann.content, ann.exact_time, ann.exact_finish, startKey, endKey)
        this.timeMap[startKey] ||= { show: [], hide: [] };
        this.timeMap[startKey].show.push(ann)


        // Dont set end times for videos, audios, jumps, questions
        if (InteractionService.isSingularInteraction(ann)) {
          // Ends at same second it shows
          endKey = convertDecitimeToKey(ann.exact_time + 0.1)
          this.timeMap[endKey] ||= { show: [], hide: [] };
          this.timeMap[endKey].hide.push(ann)
        } else {
          this.timeMap[endKey] ||= { show: [], hide: [] };
          this.timeMap[endKey].hide.push(ann)
        }
      });
      // console.log("💦 AnnStore setMap timeMap")
      // console.log("TimeMap keys and values:")
      // Object.entries(this.timeMap).forEach(([key, value]) => {
      //   console.log(key)
      //   console.log("Show", value.show)
      //   console.log("Hide", value.hide)
      // })
      // Sort timeMap by key
      this.timeMap = Object.keys(this.timeMap)
        .sort()
        .reduce((obj, key) => {
          obj[key] = this.timeMap[key];
          return obj;
        }, {});

      this.resetActive(playerStore.decitime)
    },

    resetActive(time = 0) {
      const playerStore = PlayerStore()
      // console.log("💟  AnnStore resetActive", time)
      const leadCaptureStore = LeadCaptureStore()
      // if (leadCaptureStore.checkTriggers(time)) {
      //   console.log("💟  AnnStore resetActive do lead capture", time)
      //   return
      // } else {
      //   console.log("💟  AnnStore resetActive, skipping lead capture", time)
      // }
      let t = time || playerStore.decitime

      // Convert current time to key format
      const currentKey = convertDecitimeToKey(t)

      // Clear done interactions from other times
      for (const decitime of this.doneInteractions.keys()) {
        if (decitime !== t) {
          this.clearDoneAt(decitime)
        }
      }

      this.active = []
      
      // Track questions separately to handle them after regular interactions
      // let pendingQuestions = []
      // let pendingDrawing = null

      // Process all interactions up to current time
      Object.entries(this.timeMap).forEach(([key, value]) => {
        if (key <= currentKey) {
          value.show.forEach(ann => {
            if (ann.hidden) {
              return
            }

            // Collect questions for later processing
            // if (ann.type_of === 'question') {
            //   if (t === ann.exact_time && !this.isDoneAt(ann.exact_time, ann.id)) {
            //     pendingQuestions.push(ann)
            //   }
            //   return
            // }

            // // Track the latest drawing
            // if (ann.type_of === 'drawing') {
            //   if (t === ann.exact_time && !this.isDoneAt(ann.exact_time, ann.id)) {
            //     pendingDrawing = ann
            //   }
            //   return
            // }

            // if (ann.pause && this.isDoneAt(time, ann.id)) {
            //   return
            // }

            if (ann.type_of == 'question') {
              console.log("💟 AnnStore resetActive question", ann)
            } 

            // Handle regular interactions
            else if (InteractionService.isSingularInteraction(ann)) {
              if (t === ann.exact_time && !this.isDoneAt(ann.exact_time, ann.id)) {
                this.doShow(ann)
              }
            } else {
              this.doShow(ann)
            }
          })

          // Process hide events
          value.hide.forEach(ann => {
            this.doHide(ann)
          })
        }
      })

      // Handle special interactions after processing regular ones
      // if (pendingQuestions.length > 0) {
      //   const clickStore = ClickStore()
      //   console.log("💟 AnnStore resetActive, pendingQuestions length:", pendingQuestions.length)
      //   this.activeQuestionQueue = pendingQuestions
      //   // Only show the first question if we're at its exact time
      //   if (t === pendingQuestions[0].exact_time) {
      //     clickStore.showQuestion(pendingQuestions[0])
      //     this.markAsDone(pendingQuestions[0].exact_time, pendingQuestions[0].id)
      //   }
      // } else if (pendingDrawing) {
      //   const clickStore = ClickStore()
      //   clickStore.showDrawing(pendingDrawing)
      //   this.markAsDone(pendingDrawing.exact_time, pendingDrawing.id)
      // }
    },

    async updateActive(time) {
      const clickStore = ClickStore()
      const playerStore = PlayerStore()
      if (playerStore.playerState.seeking) {
        return
      }
      const leadCaptureStore = LeadCaptureStore()
      if (leadCaptureStore.checkTriggers(time)) {
        return
      }
      // If there are any interactions marked as done at other times, clear them
      for (const decitime of this.doneInteractions.keys()) {
        if (decitime !== time) {
          this.clearDoneAt(decitime)
        }
      }

      const newInteractions = this.timeMap[convertDecitimeToKey(time)] && this.timeMap[convertDecitimeToKey(time)].show || []

      // Hide expired interactions
      const removes = this.timeMap[convertDecitimeToKey(time)] ? this.timeMap[convertDecitimeToKey(time)].hide : []
      removes.forEach(ann => {
        this.doHide(ann)
      })

      // Check if we have any questions that haven't been done yet
      const questions = newInteractions.filter(ann => 
        ann.type_of === 'question' && !this.isDoneAt(ann.exact_time, ann.id)
      )
      if (questions.length > 0) {
        // Queue up the questions
        console.log("💟 AnnStore updateActive, questions length:", questions.length)
        this.activeQuestionQueue = questions
        // Show the first question
        this.showNextQuestion()
        return
      } else if (newInteractions.some(ann => 
        ann.type_of === 'drawing' && !this.isDoneAt(ann.exact_time, ann.id)
      )) {
        const drawing = newInteractions.find(ann => 
          ann.type_of === 'drawing' && !this.isDoneAt(ann.exact_time, ann.id)
        )
        clickStore.showDrawing(drawing)
        return
      }

      // Add new items from newInteractions
      newInteractions.forEach(newInteraction => {
        this.doShow(newInteraction)
      });
    },

    // Show an interaction
    // ann_store.js - Updated doShow method
    doShow(ann) {
      console.log("💟 AnnStore doShow", ann.type_of, ann.content + " (" + ann.exact_time + " - " + ann.exact_finish + ")")

      const playerStore = PlayerStore()
      const clickStore = ClickStore()
      const playbackStore = PlaybackStore()
      const videoStore = VideoStore()
      const audioStore = AudioStore()
      const clipStore = ClipStore()

      if (InteractionService.isSingularInteraction(ann)) {
        if (this.isDoneAt(ann.exact_time, ann.id)) {
          console.log("💟 AnnStore doShow ALREADY done", ann.exact_time, ann.id)
          return
        }
      }

      const result = InteractionService.processShowInteraction(ann, {
        router: this.router,
        playerStore: playerStore,
        playbackStore: playbackStore,
        videoStore: videoStore,
        clickStore: clickStore,
        audioStore: audioStore,
        clipStore: clipStore,
        annStore: this
      })
      // console.log("💟 AnnStore doShow interaction service result", result)

      // Mark as done if interaction service returned an action
      // TODO not sure about this logic
      if (result.action) {
        this.markAsDone(ann.exact_time, ann.id)
      }

      // Handle state updates
      if (result.shouldShow && !this.active.some(interaction => interaction.id === ann.id)) {
        // console.log("💟 AnnStore doShow adding to active", ann.id)
        ann.isActive = true
        this.active.push(ann)
      }

      // console.log("💟 AnnStore doShow result.action", result.action)
      // Handle follow-up actions
      switch (result.action) {
        case 'pauseUntilClick':
          this.pausedInteraction = result.ann
          playerStore.pause("AnnStore doShow pauseUntilClick")
          break
        case 'startTimedPause':
          if (ann.exact_time == playerStore.decitime) {
            console.log("💟 AnnStore doShow startTimedPause", ann.exact_time, playerStore.decitime)
            playerStore.pause("AnnStore doShow startTimedPause")
            clickStore.startTimedPause(result.timer)
          } else {
            console.log("💟 AnnStore doShow startTimedPause clearing", ann.exact_time, playerStore.decitime)
            clickStore.clearTimedPause()
          }
          break
        case 'showMagicMenu':
          clickStore.showMagicMenu(result.ann)
          break
        case 'jump':
          playerStore.seek(result.value)
          break
        case 'setVariable':
          playbackStore.setVariable(result.variable, result.value)
          break
        case 'videoBranch':
          VideoService.branchToVideo(result.token, {
            router: this.router,
            player: playerStore,
            playback: playbackStore,
            video: videoStore
          })
          break
      }

      // console.log("AnnStore post doShowLoop")

      // Mark as done for this time if it's a singular interaction
      if (InteractionService.isSingularInteraction(ann)) {
        this.markAsDone(ann.exact_time, ann.id)
      }

      // Handle any promises from async operations
      if (result.processPromise) {
        result.processPromise.catch(error => {
          console.error("Error processing interaction:", error)
        })
      }
    },

    doHide(ann) {
      console.log("💟  AnnStore doHide", ann.type_of + " (" + ann.exact_time + " - " + ann.exact_finish + ")")
      const playerStore = PlayerStore()
      // Check if must be clicked
      if (ann.must_click && !ann.clicked) {
        console.log("Must click, time comparison: ", ann.exact_finish, playerStore.decitime, ann.exact_finish == playerStore.decitime)
        if (ann.exact_finish == playerStore.decitime) {
          this.doMustClick(ann)
          return
        }
      }
      const index = this.active.findIndex(interaction => interaction.id === ann.id)
      if (index !== -1) {
        this.active[index].isActive = false
        this.active.splice(index, 1);
      }
    },

    doPauseForAnn(ann) {
      const playerStore = PlayerStore()
      const clickStore = ClickStore()
      console.log("💟  AnnStore doPauseForAnn", ann, "Time is: ", ann.timer)
      playerStore.pause("AnnStore doPauseForAnn")
      if (ann.timer == -1) {
        console.log("💟  AnnStore doPauseForAnn pausing until click")
        this.pausedInteraction = ann
      } else {
        console.log("💟  AnnStore doPauseForAnn pausing for", ann.timer, "seconds")
        clickStore.startTimedPause(ann.timer)
      }
    },

    doPauseUntilClick(ann) {
      const playerStore = PlayerStore()
      console.log("💟  AnnStore doPauseUntilClick", ann)
      this.pausedInteraction = ann
      playerStore.pause("AnnStore doPauseUntilClick")
    },

    doMustClick(ann) {
      const playerStore = PlayerStore()
      console.log("💟  AnnStore doMustClick", ann)
      if (!this.mustClickInteractions.some(interaction => interaction.id === ann.id)) {
        this.mustClickInteractions.push(ann)
        playerStore.pause("AnnStore doMustClick")
      }
    },

    resetMustClickInteractions() {
      console.log("💟  AnnStore resetMustClickInteractions initial", this.mustClickInteractions.length)
      this.mustClickInteractions = this.mustClickInteractions.filter(interaction => !interaction.clicked)
      console.log("💟  AnnStore resetMustClickInteractions final", this.mustClickInteractions.length)
    },

    // Hovering NOT on the marker bar
    startHover(ann) {
      this.hoverAnn = ann
    },

    endHover() {
      this.hoverAnn = null
    },

    // Hovering on the marker bar
    startMarkerHover(ann) {
      this.markerAnn = ann
      this.hoverAnn = ann
    },

    endMarkerHover() {
      this.markerAnn = null
      this.hoverAnn = null
    },

    resetState() {
      this.anns = []
      this.timeMap = {}
      this.isEditing = false
      this.editAnn = null
      this.ogAnn = null
    },

    async destroy(id) {
      return this.Api.destroy(`/anns/${id}`).then(response => {
        this.errors = {};
      }).catch(error => {
        this.errors = error.response.data.errors;
      })
    },

    getVariableOptions() {
      const userStore = UserStore()
      const videoStore = VideoStore()

      const results = ['name', 'name', 'pScore', 'cScore']
      try {
        Object.keys(userStore.organization.variables).forEach((key) => {
          results.push(key)
        });
      } catch (e) {
        console.log("Error parsing org variables", e)
      }
      if (videoStore.video.variables) {
        try {
          Object.keys(videoStore.video.variables).forEach((key) => {
            results.push(key)
          });
        } catch (e) {
          console.log("Error parsing video variables", e)
        }
      }
      this.anns.forEach(ann => {
        if (ann.variable) {
          results.unshift(ann.variable)
        }
      })
      return [...new Set(results)];
    },
    // List of non-default variables
    getLimitedVariableOptions() {
      const results = this.getVariableOptions()
      return results.filter(result => result !== 'pScore' && result !== 'cScore')
    },
    addEndAnn(type) {
      this.addAnn(type)
      this.editAnn.tray_type = 'ctaTray'
      console.log("🔎 AnnStore addEndAnn", this.editAnn)
    },
    addMenuAnn(type) {
      this.addAnn(type)
      this.editAnn.tray_type = 'resourceTray'
      console.log("🔎 AnnStore addMenuAnn", this.editAnn)
    },

    showNextQuestion() {
      console.log("💟 AnnStore showNextQuestion, length:", this.activeQuestionQueue.length)
      if (this.activeQuestionQueue.length === 0) {
        console.log("💟 AnnStore showNextQuestion no more questions")
        // No more questions, resume normal playback
        const playerStore = PlayerStore()
        const clickStore = ClickStore()
        clickStore.finishQuestions()
        // this.resetActive(playerStore.decitime)
        playerStore.play("annStore showNextQuestion")
        return
      }

      const nextQuestion = this.activeQuestionQueue[0]
      console.log("💟 AnnStore showNextQuestion nextQuestion", nextQuestion.question_prompt, nextQuestion.visible)

      // Check if this question should be shown based on conditions
      // if (nextQuestion.is_conditional && !this.evaluateCondition(nextQuestion)) {
      if (!nextQuestion.visible) {
        console.log("💟 AnnStore showNextQuestion question not visible, skipping")
        // Skip this question if conditions aren't met
        this.markAsDone(nextQuestion.exact_time, nextQuestion.id) // Mark as done so we don't show it again
        this.activeQuestionQueue.shift() // Remove from queue
        this.showNextQuestion() // Process next question
        return
      }

      // Show the question
      const clickStore = ClickStore()
      clickStore.showQuestion(nextQuestion)
      this.markAsDone(nextQuestion.exact_time, nextQuestion.id)
    },

    tryFinishQuestion() {
      const clickStore = ClickStore()
      if (clickStore.isForceCorrect && !clickStore.questionCorrectness.isCorrect) {
        console.log("💟 AnnStore tryFinishQuestion force correct and incorrect, skipping")
        return
      } else {
        console.log("💟 AnnStore tryFinishQuestion, finishing")
        this.finishCurrentQuestion()
      }
    },

    finishCurrentQuestion() {
      console.log("💟 AnnStore finishCurrentQuestion, current length:", this.activeQuestionQueue.length)
      // const clickStore = ClickStore()
      // clickStore.finishTimedPause()
      // Remove the current question from the queue
      this.activeQuestionQueue.shift()

      // Show the next question or resume playback
      this.showNextQuestion()
    },

    // Called when a question is answered
    handleQuestionAnswer(questionId, answer) {
      console.log("💟 AnnStore handleQuestionAnswer", questionId, answer)
      // Store the answer for potential conditional logic
      // Process any question-specific logic
      // Then move to next question
      this.finishCurrentQuestion()
    },
    clearQuestionQueue() {
      this.activeQuestionQueue = []
    },
    // Add these methods to the actions:
    async updateImageDimensions(asset) {
      console.log("🔎 AnnStore updateImageDimensions", asset)
      this.imageLoading = true
      this.editAnn.content = asset
      try {
        await this.setEditingImageAspectRatio()
        // Force a recalculation by temporarily removing and re-adding the editAnn
        const tempAnn = { ...this.editAnn }
        this.editAnn = {}
        await nextTick()
        this.editAnn = tempAnn
      } catch (error) {
        console.error('Failed to update image dimensions:', error)
      } finally {
        this.imageLoading = false
      }
    },


  },
  getters: {
    allNeeds: (state) => {
      return {
        type_of: state.editAnn && state.editAnn.type_of,
        isQuestion: state.editAnn && state.editAnn.type_of == 'question',
        isDrawing: state.editAnn && state.editAnn.type_of == 'drawing',
        isJump: state.editAnn && state.editAnn.type_of == 'jump',
        isRedirect: state.editAnn && state.editAnn.type_of == 'redirect',
        isPause: state.editAnn && state.editAnn.type_of == 'pause',
        isSwitch: state.editAnn && state.editAnn.type_of == 'switch',
        isVariable: state.editAnn && state.editAnn.type_of == 'variable',
        isResourceTray: state.editAnn && state.editAnn.tray_type == 'resourceTray',
        isEndCta: state.editAnn && state.editAnn.tray_type == 'ctaTray',
        isResetViewer: state.editAnn && state.editAnn.type_of == 'reset_viewer',
        isChatGPT: state.editAnn && state.editAnn.type_of == 'chatGPT',
        needsPauseOptions: state.needsPauseOptions,
        needsClickAction: state.needsClickAction,
        needsClickValue: state.needsClickValue,
        needsHideTime: state.needsHideTime,
        needsPlacement: state.needsPlacement,
        needsStyling: state.needsStyling,
        needsSettings: state.needsSettings,
        isNavigation: state.isNavigation,
        needsConditional: state.needsConditional,
        needsContent: state.needsContent,
        needsVariables: state.needsVariables,
        needsTiming: state.needsTiming
      }
    },
    canUseMultiCorrect: (state) => {
      return state.isQuestion && ['free', 'date', 'number'].includes(state.editAnn.question_type)
    },
    // Hide pause controls for videos, audios, questions, navigations
    needsPauseOptions: (state) => {
      return state.editAnn && state.needsPlacement && !state.isClip && !state.isAudio && !state.isDrawing
    },
    needsAnswers: (state) => {
      console.log("🔎 AnnStore needsAnswers", state.editAnn && state.editAnn.question_type)
      return state.isQuestion && ['multiple', 'all', 'select', 'likert', 'poll'].includes(state.editAnn.question_type)
    },
    showAnswersPanel: (state) => {
      return state.isQuestion && !['rating', 'likert', 'video', 'audio', 'image'].includes(state.editAnn.question_type)
    },
    needsClickAction: (state) => {
      return state.editAnn && !state.isClip && !state.isAudio && !state.isDrawing
    },
    needsClickValue: (state) => {
      return state.needsClickAction && ['none', 'back', 'track', 'resourceTray', 'openai', 'genie'].indexOf(state.editAnn.click_action) === -1
    },
    needsHideTime: (state) => {
      return state.editAnn && state.needsPlacement && !state.editAnn.pause && state.needsClickAction
    },
    needsPlacement: (state) => {
      return state.editAnn && !state.isNavigation && !state.isQuestion && !state.isDrawing
    },
    needsStyling: (state) => {
      return state.editAnn && state.needsPlacement && !state.isNavigation && !state.isDrawing
    },
    needsSettings: (state) => {
      return state.editAnn && !state.isNavigation && !state.isDrawing
    },
    isNavigation: (state) => {
      return state.editAnn && ['jump', 'pause', 'redirect', 'switch', 'variable', 'resourceTray', 'reset_viewer', 'chatGPT', 'back'].indexOf(state.editAnn.type_of) !== -1
    },
    isQuestion: (state) => {
      return state.editAnn && state.editAnn.type_of == 'question'
    },
    isDrawing: (state) => {
      return state.editAnn && state.editAnn.type_of == 'drawing'
    },
    isHotspot: (state) => {
      return state.editAnn && state.editAnn.type_of == 'hotspot'
    },
    isClip: (state) => {
      return state.editAnn && state.editAnn.type_of == 'clip'
    },
    isAudio: (state) => {
      return state.editAnn && state.editAnn.type_of == 'audio'
    },
    needsConditional: (state) => {
      // TODO any dont need conditional?
      return !state.isDrawing
    },
    needsContent: (state) => {
      return state.editAnn && state.needsPlacement && !state.isNavigation && !state.isHotspot && !state.isDrawing
    },
    needsVariables: (state) => {
      return state.editAnn && state.needsPlacement && !state.isNavigation && !state.isDrawing
    },
    needsTiming: (state) => {
      return state.editAnn && !state.editAnn.tray_type
    },
    validateAnnotation: (state) => {
      let a = state.editAnn
      let result = { valid: true, message: '', element: null }
      if (!a.content && state.needsContent) {
        result = { valid: false, message: 'common.content_required', element: 'edit-ann-content' }
      } else if (a.exact_time > a.exact_finish) {
        result = { valid: false, message: 'common.show_time_must_be_before_finish_time', element: 'edit-ann-exact-time' }
      } else if (!a.click_action && state.needsClickAction) {
        result = { valid: false, message: 'common.click_action_required', element: 'edit-ann-click-action' }
      } else if (!a.click_value && state.needsClickValue) {
        result = { valid: false, message: 'common.click_value_required', element: 'edit-ann-click-value' }
      } else if (a.click_action == 'link' && !isValidURL(a.click_value)) {
        result = { valid: false, message: 'common.valid_url_required', element: 'edit-ann-click-value' }
      } else if (a.is_conditional) {
        if (!a.conditional_variable) {
          result = { valid: false, message: 'common.conditional_variable_required', element: 'edit-ann-conditional-variable' }
        } else if (!a.conditional_assertion) {
          result = { valid: false, message: 'common.conditional_assertion_required', element: 'edit-ann-conditional-operator' }
        } else if (!a.conditional_value) {
          result = { valid: false, message: 'common.conditional_value_required', element: 'edit-ann-conditional-value' }
        }
      } else if (a.variable && !a.value) {
        result = { valid: false, message: 'common.variable_value_required', element: 'edit-ann-variable-value' }
      } else if (a.value && !a.variable) {
        result = { valid: false, message: 'common.variable_required', element: 'edit-ann-variable' }
      }
      console.log("🔎 AnnStore validateAnnotation", result)
      return result
    },
    validateNavigation: (state) => {
      return { valid: true, message: '', element: null }
    }
  }
})