import dayjs from 'dayjs'
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
dayjs.extend(isToday);
dayjs.extend(isYesterday);

export function DateComposer() {
  const { t } = useI18n({})

  function mmdd(date) {
    const formatted = dayjs(date).format('MM/D')
    return formatted.replace(/^0/, '')
  }
  function dmyyyy(date) {
    const formatted = dayjs(date).format('MM/D/YY')
    return formatted.replace(/^0/, '')
  }
  function dmyyyy_dash(date) {
    const formatted = dayjs(date).format('MM-D-YY')
    return formatted.replace(/^0/, '')
  }
  function hhmmyyyy(date) {
    const formatted = dayjs(date).format('MMM D, YYYY')
    return formatted.replace(/^0/, '')
  }
  function hhmmyyyyt(date) {
    const formatted = dayjs(date).format('MMM D YYYY h:mm a')
    return formatted.replace(/^0/, '')
  }
  function hhmma(date) {
    const formatted = dayjs(date).format('h:mm a')
    return formatted.replace(/^0/, '')
  }
  function hhmm(date) {
    const formatted = dayjs(date).format('h:mm')
    return formatted.replace(/^0/, '')
  }
  function daysFromNow(date) {
    return Math.abs(dayjs().diff(dayjs(date), 'days'))
  }

  function tableDateFormat(date) {
    console.log("🌜 DateComposer tableDateFormat", date)
    if (!date) {
      return ''
    }
    return dayjs(date).isToday()
      ? `${t("common.today")} ${dayjs(date).format(" @ h:mm a")}`
      : date.includes("2025")
      ? dayjs(date).format("M-DD-YY @ h:mm a")
      : dayjs(date).format("MM-DD-YYYY @ h:mm a")
  }

  return {
    hhmm,
    mmdd,
    dmyyyy,
    dmyyyy_dash,
    hhmmyyyy,
    hhmmyyyyt,
    daysFromNow,
    hhmma,
    tableDateFormat,
  }
}
