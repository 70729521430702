import { PlayerStore } from '@/front/stores/player_store.js';

import { PlaybackStore } from '@/front/stores/playback_store.js';

import { VideoStore } from '@/front/stores/video_store.js';

import { TimeFormatter } from '@/front/composables/TimeFormatter.js';
const { formatSeconds } = TimeFormatter()

// For window postMessage sending and receiving

export function MessageComposer() {

  function emitMessage(str, data) {
    const videoStore = VideoStore();
    const playerStore = PlayerStore();
    const playbackStore = PlaybackStore();
    let video = videoStore.video;

    var obj = {event: str, version: 1, info: { 
      data: data, 
      title: video.title, 
      token: video.token, 
      currentTime: playerStore.fulltime, 
      formattedTime: formatSeconds(playerStore.fulltime), 
      duration: video.duration, 
      formattedDuration: formatSeconds(video.duration), 
      variables: playbackStore.variables, 
      viewer: { id: playbackStore.viewer.id, name: playbackStore.viewer.name, email: playbackStore.viewer.email, custom_id: playbackStore.viewer.custom_id} 
    }}

    console.log("🎩 EmitMessage:")
    console.log(obj)
    // playbackStore.messages.unshift(obj)
    // if (window.top && window.top !== window) {
    //   window.top.postMessage(messageObj, "*");
    // }
  }

  function receiveMessage(event) {
    console.log("🎩 ReceiveMessage:")
    console.log(event)
    const playerStore = PlayerStore();
    const playbackStore = PlaybackStore();

    let action = event.data.event

    switch (action) {
      case "request_screenshot":
        console.log("✅ Screenshot")
        playerStore.takeScreenshot().then((dataUrl) => {
        emitMessage("screenshot", dataUrl)
        })
        break
      case "pause_player":
        console.log("✅ Pause")
        playerStore.pause()
        break
      case "enter_fullscreen":
      // Does not work due to security restrictions
      console.log("✅ Enter Fullscreen")
      playerStore.enterFullscreen()
      break
      case "exit_fullscreen":
        console.log("✅ Exit Fullscreen")
        playerStore.exitFullscreen()
        break
      case "play_player":
      console.log("✅ Play")
        playerStore.play()
        break
      case "rewind":
        console.log("✅ Rewind")
        if (playerStore.playerState.paused) {
          playerStore.seek(playerStore.player.currentTime - 1)
        } else {
          playerStore.seek(playerStore.player.currentTime - 5)
        }
        break
      case "fast_forward":
      console.log("✅ FastForward")
        if (playerStore.playerState.paused) {
          playerStore.seek(playerStore.player.currentTime + 1)
        } else {
          playerStore.seek(playerStore.player.currentTime + 5)
        }
        break
      case "change_time":
        console.log("✅ ChangeTime")
        playerStore.seek(event.data.time || playerStore.currentTime)
        break
      case "set_variable":
      console.log("✅ SetVariable")
        playbackStore.setVariable(event.data.variable_name, event.data.variable_value)
        break
      case "godoot":
        console.log("✅ GoDoot")
        playerStore.isDebug = true
        break
      case "closeModal":
        // TODO
        console.log("✅ GoDoot")
        break;
      default:
        console.log("✅ Unknown")
        break;
    }
  }

  function trackGtagEvent(event, category) {
    console.log("🎩 TrackGtagEvent", event, category)
  }

  return { emitMessage, receiveMessage, trackGtagEvent }
}