<script setup>
import { UserStore } from '@/front/stores/user_store.js'
const userStore = UserStore();
import { useRoute } from 'vue-router'
const router = useRouter()
const location = useRoute()
import { useI18n } from 'vue-i18n'
const { t } = useI18n({});
import { InteractionStore } from '@/front/stores/interaction_store.js'
const interactionStore = InteractionStore();
import ApexCharts from 'apexcharts'
import { DateComposer } from '@/front/composables/DateComposer.js'
const { mmdd } = DateComposer()
import { StringComposer } from '@/front/composables/StringComposer.js'
const { countryToEmoji } = StringComposer()
import { ClickStore } from '@/front/stores/click_store.js'
const clickStore = ClickStore();

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  height: {
    type: Number,
    default: 250
  },
  download: {
    type: Boolean,
    default: true
  }
})
let chartData = null


onMounted(() => {
  console.log("GetChartData", getChartData())
  updateChart()
});

const getPollData = () => {
  let result = {}
  clickStore.questionActive.question_answers.forEach(answer => {
    result[answer.text] = answer.count || 0
  })  
  return result
}

const getCountryData = () => {
  let result = {}
  interactionStore.insights.stats.forEach(stat => {
    stat.countries.forEach(country => {
      if (result[country[0]]) {
        result[country[0]] += country[1]
      } else {
        result[country[0]] = country[1]
      }
    })
  })
  result = Object.fromEntries(
    Object.entries(result).sort(([, a], [, b]) => b - a)
  )
  console.log("🌞 CountryData: ", result)
  let categories = []
  Object.keys(result).forEach(country => {
    categories.push(countryToEmoji(country) + ' ' + country)
  })
  return {
    categories: categories,
    data: Object.values(result),
  }
}

const getVideoData = () => {
  let result = {}
  interactionStore.insights.stats.forEach(stat => {
    stat.group_videos.forEach(video => {
      if (result[video[0]]) {
        result[video[0]] += video[1]
      } else {
        result[video[0]] = video[1]
      }
    })
  })
  result = Object.fromEntries(
    Object.entries(result).sort(([, a], [, b]) => b - a)
  )
  console.log("🌞 VideoData: ", result)
  let categories = []
  Object.keys(result).forEach(video => {
    categories.push(video)
  })
  return {
    categories: categories,
    data: Object.values(result),
  }
}

const getChartData = () => {
  switch (props.type) {
    case 'poll':
      const pollData = getPollData()
      return {
        downloadTitle: `${t('common.poll')}`,
        name: `${t('common.responses')}:`,
        data: Object.values(pollData),
        categories: Object.keys(pollData),
        horizontal: false,
        colors: ['#1098ff', '#FFB3B2', '#FFD500'], // Light blue and Light red
        formatter: (value) => {
          return value
        }
      }
    case 'leads':
      return {
        title: `${t('common.leads')}: ${interactionStore.insights.summary.leads}`,
        downloadTitle: `${t('common.leads')}`,
        name: t('common.viewers'),
        data: [
          { x: t('common.leads'), y: interactionStore.insights.summary.leads, fillColor: '#CBC3E3', strokeColor: '#FFD500' },
          { x: t('common.anonymous'), y: interactionStore.insights.summary.viewers - interactionStore.insights.summary.leads, fillColor: '#51414F', strokeColor: '#1098ff' }
        ],
        categories: [t('common.leads'), t('common.anonymous')],
        horizontal: false,
        colors: ['#1098ff', '#FFB3B2', '#FFD500'], // Light blue and Light red
        formatter: (value) => {
          return value
        }
      }
    case 'countries':
      const countryData = getCountryData()
      return {
        title: `${t('common.countries')}`,
        downloadTitle: `${t('common.countries')}`,
        name: t('common.views'),
        data: countryData.data,
        categories: countryData.categories,
        headerCategory: t('common.country'),
        headerValue: t('common.views'),
        horizontal: true,
        colors: ['#1098ff', '#FFB3B2'], // Light blue and Light red
        formatter: (value) => {
          return value
        }
      }
    case 'questions':
      return {
        title: `${t('common.questions')}`,
        downloadTitle: `${t('common.questions')}`,
        name: t('common.questions'),
        data: [
          { x: t('common.correct'), y: interactionStore.insights.summary.correct, fillColor: '#5CED73', strokeColor: '#FFD500' },
          { x: t('common.incorrect'), y: interactionStore.insights.summary.incorrect, fillColor: '#F72C5B', strokeColor: '#FFD500' }
        ],
        categories: [t('common.correct'), t('common.incorrect')],
        horizontal: false,
        formatter: (value) => {
          return value
        }
      }
    case 'devices':
      return {
        title: `${t('common.devices')}`,
        downloadTitle: `${t('common.devices')}`,
        name: t('common.views'),
        data: [
          { x: t('common.computer'), y: interactionStore.insights.summary.laptops, fillColor: '#118B50', strokeColor: '#FFD500' },
          { x: t('common.phone'), y: interactionStore.insights.summary.phones, fillColor: '#5DB996', strokeColor: '#FFD500' },
          { x: t('common.tablet'), y: interactionStore.insights.summary.tablets, fillColor: '#D39D55', strokeColor: '#FFD500' }
        ],
        categories: [t('common.computer'), t('common.phone'), t('common.tablet')],
        horizontal: false,
        formatter: (value) => {
          return value
        }
      }
    case 'videos':
      const videoData = getVideoData()
      return {
        title: `${t('common.top_videos')}`,
        name: "Videos",
        data: videoData.data,
        categories: videoData.categories,
        horizontal: true,
        formatter: (value) => {
          return value
        }
      }
  }
  return []
}


const updateChart = () => {
  chartData = getChartData()
  // console.log("🌞 BarChartData: ", chartData)
  var options = {
    series: [chartData],
    chart: {
      height: props.height,
      // width: 600,
      type: 'bar',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: props.download,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        export: {
          scale: undefined,
          width: undefined,
          csv: {
            filename: chartData.downloadTitle,
            columnDelimiter: ',',
            headerCategory: chartData.headerCategory,
            headerValue: chartData.headerValue,
          },
          svg: {
            filename: chartData.downloadTitle,
            columnDelimiter: ',',
            headerCategory: chartData.headerCategory,
            headerValue: chartData.headerValue,
          },
          png: {
            filename: chartData.downloadTitle,
            columnDelimiter: ',',
            headerCategory: chartData.headerCategory,
            headerValue: chartData.headerValue,
          }
        },
        autoSelected: 'zoom' 
      },
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        horizontal: chartData.horizontal,
      }
    },
    stroke: {
      curve: 'smooth',
      width: 0,
    },
    colors: chartData.colors,
    title: {
      text: chartData.title,
      align: 'left',
      style: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#333'
      }
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
      },

    },

    markers: {
      size: 4,
      hover: {
        sizeOffset: 6
      },
    },
    xaxis: {
      categories: chartData.categories,
      labels: {
        style: {
          fontSize: props.type === 'poll' ? '18px' : '14px',
          fontWeight: 'bold',
          color: '#333',
          padding: '10px'
        }
      }
    },
    yaxis: {
      categories: chartData.categories,
      labels: {
        formatter: function (val) {
          return chartData.formatter(val)
        }
      }
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return chartData.formatter(val)
            }
          }
        },
      ]
    },
    grid: {
      borderColor: '#f1f1f1',
    }
  };

  var chart = new ApexCharts(document.querySelector(`#bar-chart-${props.type}`), options);
  chart.render();
}




</script>

<template>
  <div :id="`bar-chart-${props.type}`">

  </div>
</template>
